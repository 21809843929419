import * as React from 'react';
import { InputProps } from 'reactstrap';
import { action, observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { toFixed } from 'util/helpers';
import InputWithNullCheck from 'components/InputWithNullCheck';
import * as classnames from 'classnames';

type InputPropsWithoutOnChange = Omit<InputProps, 'onChange'>;

interface INumberProps extends InputPropsWithoutOnChange {
  decimalPlaces: number;
  value: number;
  onChangeValue?: (value: number, e?: React.ChangeEvent<HTMLInputElement>) => void;
  allowNegativeValue?: boolean;
}

class InputNumber extends React.Component<INumberProps> {
  private _value: string = this.props.value ? this.props.value.toFixed(this.props.decimalPlaces) : '';

  private _isInputFocused: boolean = false;

  constructor(props: INumberProps) {
    super(props);

    makeObservable<InputNumber, '_value' | '_isInputFocused' | '_changeValue' | '_toggleIsInputFocused'>(this, {
      _value: observable,
      _isInputFocused: observable,
      _changeValue: action,
      _toggleIsInputFocused: action,
    });
  }

  // this method need for formating data from server
  public componentDidUpdate(prevProps: Readonly<INumberProps>): void {
    if (prevProps.value !== this.props.value && !this._isInputFocused) {
      this._changeValue(this._formatData(String(this.props.value)));
    }
  }

  public render() {
    // prevent passing allowNegativeValue to the DOM
    const { onChangeValue, decimalPlaces, value, onBlur, allowNegativeValue, className, ...inputProps } = this.props;

    return (
      <InputWithNullCheck
        {...inputProps}
        type="number"
        pattern="[0-9]*"
        inputMode={decimalPlaces ? 'decimal' : 'numeric'}
        value={this._value}
        onChange={this._onChange}
        onBlur={this._onBlur}
        onFocus={this._onFocus}
        className={classnames(['no-arrows', className])}
        onWheel={this._onWheel}
      />
    );
  }

  // This method is needed for IE, because IE doesn't support type=number
  private _onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { onChangeValue } = this.props;
    const inputValue = e.target.value;
    const isTargetValueNumber = !isNaN(Number(inputValue));
    if (!isTargetValueNumber || !this._isValidInput(Number(inputValue))) {
      return;
    }

    if (onChangeValue) {
      onChangeValue(inputValue ? parseFloat(inputValue) : null, e);
    }

    this._changeValue(inputValue);
  };

  private _isValidInput = (value: number): boolean => {
    return this._isSignValid(value) && !this._hasMoreDigitsInFloatingPartThanExpected(value);
  };

  private _isSignValid = (value: number): boolean => {
    const { allowNegativeValue } = this.props;

    return allowNegativeValue ? true : Math.sign(value) >= 0;
  };

  private _hasMoreDigitsInFloatingPartThanExpected = (value: number): boolean => {
    const { decimalPlaces } = this.props;
    return value > toFixed(value, decimalPlaces);
  };

  private _formatData = (value: string): string => {
    const formatedValue = parseFloat(value).toFixed(this.props.decimalPlaces);
    return isNaN(Number(formatedValue)) ? '' : formatedValue;
  };

  private _onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { onFocus } = this.props;
    this._toggleIsInputFocused();

    if (onFocus) {
      onFocus(e);
    }
  };

  private _onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { onBlur } = this.props;
    this._changeValue(this._formatData(this._value));
    this._toggleIsInputFocused();

    if (onBlur) {
      onBlur(e);
    }
  };

  private _onWheel = (e: React.WheelEvent<HTMLInputElement>) => {
    if (this._isInputFocused) {
      e.currentTarget.blur();
    }
  };

  private _changeValue = (newValue: string) => {
    this._value = newValue;
  };

  private _toggleIsInputFocused = () => {
    this._isInputFocused = !this._isInputFocused;
  };
}

export default observer(InputNumber);
