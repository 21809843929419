import { action, observable, computed, makeObservable } from 'mobx';
import RootStore from 'stores/RootStore';
import TenantModel from 'models/TenantModel';
import WorkflowModel from 'models/WorkflowModel';
import UserConfigurationModel from 'models/UserConfigurationModel';
import { CountryCode } from 'util/enums';
import { DATE_FORMAT_DEFAULT } from 'util/constants';

export default class ViewStore {
  public tenant: TenantModel = null;

  public constructor(private readonly rootStore: RootStore) {
    makeObservable<ViewStore, '_activeConfiguration' | '_activeWorkflow'>(this, {
      tenant: observable,
      setTenant: action,
      activeTenant: computed,
      countryCode: computed,
      isUK: computed,
      isUS: computed,
      isDE: computed,
      isDE_D365: computed,
      isIT: computed,
      isFR: computed,
      isAdminFromUS: computed,
      isAdminFromFR: computed,
      isAdminFromDE: computed,
      isAdminFromDE_D365: computed,
      isLabFromDE: computed,
      isLabFromDE_D365: computed,
      isLabFromUS: computed,
      isTier2FromDE: computed,
      isTier2FromDE_D365: computed,
      isTier2FromUS: computed,
      isTier1FromDE: computed,
      isTier1FromDE_D365: computed,
      isTraderFromIT: computed,
      isTraderFromFR: computed,
      isTier3FromIT: computed,
      isTier2FromIT: computed,
      isTier1FromIT: computed,
      isTier3FromFR: computed,
      isTier2FromFR: computed,
      isTier1FromFR: computed,
      numberOfDecimals: computed,
      dateFormat: computed,
      stockCodeFormat: computed,
      weighbridgeTolerance: computed,
      timezone: computed,
      hasAgSignOff: computed,
      hasOverwriteRgDescriptionDialogEnabled: computed,
      rgClassificationsSectionRequired: computed,
      rgContaminationsSectionRequired: computed,
      _activeConfiguration: computed,
      _activeWorkflow: computed,
    });
  }

  public setTenant(tenant: TenantModel) {
    this.tenant = tenant;
  }

  public get activeTenant(): TenantModel {
    return this.tenant || this.rootStore.sessionStore.tenant;
  }

  public get countryCode(): CountryCode {
    return this._activeWorkflow && this.activeTenant.workflow.code;
  }

  public get isUK() {
    return this._activeWorkflow.isUK;
  }

  public get isUS() {
    return this._activeWorkflow.isUS;
  }

  public get isDE() {
    return this._activeWorkflow.isDE;
  }

  public get isDE_D365() {
    return this._activeWorkflow.isDE_D365;
  }

  public get isIT() {
    return this._activeWorkflow.isIT;
  }

  public get isFR() {
    return this._activeWorkflow.isFR;
  }

  public get isAdminFromUS() {
    return this.isUS && this.rootStore.sessionStore.isAdmin;
  }

  public get isAdminFromFR() {
    return this.isFR && this.rootStore.sessionStore.isAdmin;
  }

  public get isAdminFromDE() {
    return this.isDE && this.rootStore.sessionStore.isAdmin;
  }

  public get isAdminFromDE_D365() {
    return this.isDE_D365 && this.rootStore.sessionStore.isAdmin;
  }

  public get isLabFromDE() {
    return this.isDE && this.rootStore.sessionStore.isLab;
  }

  public get isLabFromDE_D365() {
    return this.isDE_D365 && this.rootStore.sessionStore.isLab;
  }

  public get isLabFromUS() {
    return this.isUS && this.rootStore.sessionStore.isLab;
  }

  public get isTier2FromDE() {
    return this.isDE && this.rootStore.sessionStore.isTier2;
  }

  public get isTier2FromDE_D365() {
    return this.isDE_D365 && this.rootStore.sessionStore.isTier2;
  }

  public get isTier2FromUS() {
    return this.isUS && this.rootStore.sessionStore.isTier2;
  }

  public get isTier1FromDE() {
    return this.isDE && this.rootStore.sessionStore.isTier1;
  }

  public get isTier1FromDE_D365() {
    return this.isDE_D365 && this.rootStore.sessionStore.isTier1;
  }

  public get isTraderFromIT() {
    return this.isIT && this.rootStore.sessionStore.isTrader;
  }

  public get isTraderFromFR() {
    return this.isFR && this.rootStore.sessionStore.isTrader;
  }

  public get isTier3FromIT() {
    return this.isIT && this.rootStore.sessionStore.isTier3;
  }

  public get isTier2FromIT() {
    return this.isIT && this.rootStore.sessionStore.isTier2;
  }

  public get isTier1FromIT() {
    return this.isIT && this.rootStore.sessionStore.isTier1;
  }

  public get isTier3FromFR() {
    return this.isFR && this.rootStore.sessionStore.isTier3;
  }

  public get isTier2FromFR() {
    return this.isFR && this.rootStore.sessionStore.isTier2;
  }

  public get isTier1FromFR() {
    return this.isFR && this.rootStore.sessionStore.isTier1;
  }

  public get numberOfDecimals(): number {
    return this._activeConfiguration ? this._activeConfiguration.numberOfDecimals : 0;
  }

  public get dateFormat(): string {
    return this._activeConfiguration ? this._activeConfiguration.dateFormat : DATE_FORMAT_DEFAULT;
  }

  public get stockCodeFormat(): string {
    return this._activeConfiguration ? this._activeConfiguration.stockCodeFormat : '';
  }

  public get weighbridgeTolerance(): number {
    return this._activeConfiguration ? this._activeConfiguration.weighbridgeTolerance : 0;
  }

  public get timezone(): string {
    return this.activeTenant && this.activeTenant.timezone;
  }

  public get hasAgSignOff(): boolean {
    return this.activeTenant?.agSignOffEnabled;
  }

  public get hasOverwriteRgDescriptionDialogEnabled(): boolean {
    return this.activeTenant.overwriteRgDescriptionDialogEnabled;
  }

  public get shouldDisplayTareWeightForReceivedGood(): boolean {
    return this.activeTenant.displayTareWeightForReceivedGood;
  }

  public get rgClassificationsSectionRequired(): boolean {
    return this.activeTenant?.rgClassificationsSectionRequired;
  }

  public get rgContaminationsSectionRequired(): boolean {
    return this.activeTenant?.rgContaminationsSectionRequired;
  }

  private get _activeConfiguration(): UserConfigurationModel {
    return this._activeWorkflow && this._activeWorkflow.configuration;
  }

  private get _activeWorkflow(): WorkflowModel {
    return this.activeTenant && this.activeTenant.workflow;
  }
}
