import { I18N } from './i18n';

// ignore warning as we are defining it lazily
const lang: I18N = {} as I18N;

lang.GLOBAL_LABEL_YES = 'Sì';
lang.GLOBAL_LABEL_YES_SMALL = 'sì';
lang.GLOBAL_LABEL_NO = 'No';
lang.GLOBAL_LABEL_NO_SMALL = 'no';
lang.GLOBAL_LABEL_SUBMIT = 'Sottoporre';
lang.GLOBAL_LABEL_RESULT = 'Risultato';
lang.GLOBAL_LABEL_ERROR = 'Errore';
lang.GLOBAL_LABEL_REFRESH = 'Ristorarsi';
lang.GLOBAL_LABEL_ADD_NEW = 'Aggiungere nuovo';
lang.GLOBAL_LABEL_NAME = 'Nome';
lang.GLOBAL_LABEL_CODE = 'Codice ';
lang.GLOBAL_LABEL_UNIQUE_ID = 'ID univoco';
lang.GLOBAL_LABEL_LOCATION = 'Settore';
lang.GLOBAL_LABEL_ONLINE = 'Online';
lang.GLOBAL_LABEL_IS_DEFAULT = 'Essere inadempiente';
lang.GLOBAL_LABEL_MODEL = 'Modello';
lang.GLOBAL_LABEL_IP = 'IP';
lang.GLOBAL_LABEL_SAVE = 'Salvo';
lang.GLOBAL_LABEL_DELETE = 'Eliminare';
lang.GLOBAL_LABEL_CANCEL = 'Cancellare';
lang.GLOBAL_LABEL_UPLOAD = 'Upload';
lang.GLOBAL_LABEL_FILE = 'File';
lang.GLOBAL_LABEL_PORT = 'Porta';
lang.GLOBAL_LABEL_FIRST_NAME = 'Nome';
lang.GLOBAL_LABEL_LAST_NAME = 'Cognome';
lang.GLOBAL_LABEL_USERNAME = 'Nome utente';
lang.GLOBAL_LABEL_PASSWORD = 'Parola d\'ordine';
lang.GLOBAL_LABEL_EMAIL = 'E-mail';
lang.GLOBAL_LABEL_ROLE = 'Funzione';
lang.GLOBAL_LABEL_STATUS = 'Stato';
lang.GLOBAL_LABEL_REMARKS = 'Commenti';
lang.GLOBAL_LABEL_SELECT = 'Selezionare';
lang.GLOBAL_LABEL_GRN = 'BE';
lang.GLOBAL_LABEL_IN_PROGRESS = 'In corso';
lang.GLOBAL_LABEL_COMPLETED = 'Completati';
lang.GLOBAL_LABEL_REGISTER = 'Registrati';
lang.GLOBAL_LABEL_OPERATION = 'operazione';
lang.GLOBAL_LABEL_SUPPLIER = 'Fornitore';
lang.GLOBAL_LABEL_SUPPLIER_CITY = 'Città fornitore';
lang.GLOBAL_LABEL_DRIVER = 'Macchinista';
lang.GLOBAL_LABEL_LICENCE_PLACE = 'Targa';
lang.GLOBAL_LABEL_TO_CLAIM = 'Rivendica';
lang.GLOBAL_LABEL_CREATED = 'Arrivato';
lang.GLOBAL_LABEL_ACTIONS = 'Azioni';
lang.GLOBAL_LABEL_CLAIM = 'Rivendicazione';
lang.GLOBAL_LABEL_DESCRIPTION = 'Descrizione';
lang.GLOBAL_LABEL_ADD = 'Aggiungere';
lang.GLOBAL_LABEL_NOT_CLAIMED = 'Disponibile';
lang.GLOBAL_LABEL_DELIVERIES = 'Consegne';
lang.GLOBAL_LABEL_SAVE_ATTACHMENTS = 'Salvare gli allegati';
lang.GLOBAL_LABEL_UPLOAD_ATTACHMENTS = 'Caricare allegati';
lang.GLOBAL_LABEL_ATTACHMENTS = 'Allegati';
lang.GLOBAL_LABEL_ATTACHMENT = 'Allegato';
lang.GLOBAL_LABEL_ADVISED_GOODS = 'Merci consigliati';
lang.GLOBAL_LABEL_SYNC = 'Sync';
lang.GLOBAL_LABEL_CLOSE = 'Vicino';
lang.GLOBAL_LABEL_VERIFY = 'Verificare';
lang.GLOBAL_LABEL_ALERT = 'BLOCCARE';
lang.GLOBAL_LABEL_ALERT_RECEIVED_GOOD = '!!! LA MERCE RICEVUTA VIENE BANDIERATA !!!';
lang.GLOBAL_LABEL_CLAIM_BY = 'Rivendicato da';
lang.GLOBAL_LABEL_CLASSIFIED_BY = 'Classificazione da';
lang.GLOBAL_LABEL_VERIFIED_BY = 'Verificato da';
lang.GLOBAL_LABEL_ROLLEDBACK_BY = 'Resettato da';
lang.GLOBAL_LABEL_FLAGGED_BY = 'Contrassegnato da';
lang.GLOBAL_LABEL_UNFLAGGED_BY = 'Senza rinforzato da';
lang.GLOBAL_LABEL_TRANSFERS = 'Trasferimenti';
lang.GLOBAL_LABEL_TRANSFERED = 'Trasferita';
lang.GLOBAL_LABEL_WEIGHT = 'Peso';
lang.GLOBAL_LABEL_CREATE = 'Creare';
lang.GLOBAL_LABEL_CHARACTERS_REMAINING = (number) => `Caratteri rimanenti: ${number}`;
lang.GLOBAL_LABEL_HIDE = 'Nascondere';
lang.GLOBAL_LABEL_UNHIDE = 'Mostrare';
lang.GLOBAL_LABEL_SHOW = 'Mostrare';
lang.GLOBAL_LABEL_LANGUAGE = (language) => `Lingua: ${language}`;
lang.GLOBAL_LABEL_FILE_DOWNLOADED_MSG = 'File scaricato';
lang.GLOBAL_LABEL_DECIMALS = 'Decimali';
lang.GLOBAL_LABEL_GO_BACK = 'Tornare indietro';
lang.GLOBAL_LABEL_VERSION = (version) => `Versione - ${version}`;
lang.GLOBAL_LABEL_CONFIRM = 'Confermare';
lang.GLOBAL_LABEL_FIELD = 'Campo';
lang.GLOBAL_LABEL_FIELDS = 'Campi';
lang.GLOBAL_LABEL_NONE = 'Nessuno';
lang.GLOBAL_LABEL_ALL_TENANTS = 'Tutti gli inquilini';
lang.GLOBAL_LABEL_FIELD_NAME_NOT_FIND_ERROR = 'Il nome del campo di immissione non è definito';
lang.GLOBAL_LABEL_UNCLAIM = 'Annulli la rivendicazione';
lang.GLOBAL_LABEL_CERTIFICATE_EXPIRATION_DATE = 'Scad. 333/EU';
lang.GLOBAL_LABEL_CERT_AUT_RIF_DATE = 'Scad. aut. rif.';
lang.GLOBAL_LABEL_CERT_715_13 = 'Cert. 715/13';
lang.GLOBAL_LABEL_TYPE = 'genere';
lang.GLOBAL_LABEL_REVISION_REQUEST = 'Richiesta di revisione';
lang.GLOBAL_LABEL_EXPIRED = 'Scadute';
lang.GLOBAL_LABEL_NO_CERTIFICATE = 'Senza certificato';
lang.GLOBAL_LABEL_RESPONSIBLE = 'Responsabile';
lang.GLOBAL_LABEL_DEPARTMENT = 'Dipartimento';
lang.GLOBAL_LABEL_EXPORT = 'Esportare';
lang.GLOBAL_LABEL_REEXPORT = 'Riesportare';
lang.GLOBAL_LABEL_FLAGGED = 'Segnalato';
lang.GLOBAL_LABEL_EXPORTED = 'Esportata';
lang.GLOBAL_LABEL_SYNCED = 'Sincronizzato';
lang.GLOBAL_LABEL_PRINT = 'Stampa';
lang.GLOBAL_LABEL_FLAG = 'CONTRASSEGNO';
lang.GLOBAL_LABEL_UN_FLAG = 'RIMUOVERE IL CONTRASSEGNO';
lang.GLOBAL_LABEL_NO_AUTHORIZATION_NUMBER = 'Numero di autorizzazione non presente';
lang.GLOBAL_LABEL_AUTHORIZATION_EXPIRED = 'Autorizzazione scaduta';
lang.GLOBAL_LABEL_NO_EXPIRATION_DATE = 'Data di scadenza non presente';
lang.GLOBAL_LABEL_AUTH_NUMBER = 'Num. di autoriz';
lang.GLOBAL_LABEL_EXP_DATE = 'Data di scad';
lang.GLOBAL_LABEL_IS_NOT_VALID = (name) => `.${name} non è valido`;
lang.GLOBAL_LABEL_INVALID_FIELDS = (name) => `.Campi non validi: ${name}`;
lang.GLOBAL_LABEL_USER_ROLES = '.Ruoli dell\'utente';
lang.GLOBAL_LABEL_DEFAULT_ROLE = '.Ruolo predefinito';
lang.GLOBAL_LABEL_DEFAULT_TENANT = '.Tenant predefinito';
lang.GLOBAL_LABEL_NEW_VERSION_RELEASED = '.La nuova versione è stata rilasciata.';
lang.GLOBAL_LABEL_REFRESH_PAGE_CONFIRMATION = '.Per applicare le modifiche: aggiorna la pagina. Tutte le modifiche non salvate andranno perse!';
lang.GLOBAL_LABEL_TENANTS_AVAILABLE = '.Inquilini disponibili';
lang.GLOBAL_LABEL_AVAILABLE_TENANTS_FOR_ROLE = (roleName) => `.Gli inquilini disponibili per ${roleName}`;
lang.GLOBAL_LABEL_IMPORT = '.Importare';
lang.GLOBAL_LABEL_PASSWORD_EXPIRATION_MESSAGE = (date) => `.La tua password scade il ${date}. Si prega di modificare la password il prima possibile per evitare il blocco dell\'account`;
lang.GLOBAL_LABEL_PASSWORD_EXPIRED_MESSAGE = 'La tua password è scaduta. Si prega di impostarne uno nuovo';
lang.GLOBAL_LABEL_PROCESSING = 'in lavorazione';
lang.GLOBAL_LABEL_PRICE = 'Prezzo';
lang.GLOBAL_LABEL_UNIT = 'Unità';
lang.GLOBAL_LABEL_BREAKDOWN = 'Abbattersi';
lang.GLOBAL_LABEL_PASSWORD_EXP_DATE = 'Scadenza password Data';
lang.GLOBAL_LABEL_SUPPLIER_TYPE_ITALIA = 'Italia';
lang.GLOBAL_LABEL_SUPPLIER_TYPE_ESTERO = 'Estero';
lang.GLOBAL_LABEL_SUPPLIER_TYPE_DITTA = 'Ditta';
lang.GLOBAL_LABEL_SUPPLIERS_DETAILS = 'Dettagli fornitori';
lang.GLOBAL_LABEL_FORM = 'Modulo';
lang.GLOBAL_LABEL_QUALITY = 'Qualità';
lang.GLOBAL_LABEL_REFRESH_PRODUCT_VARIANTS = 'Aggiorna le varianti di prodotto';
lang.GLOBAL_LABEL_WEIGHT_UNITS = 'Unità di peso';
lang.GLOBAL_LABEL_EMPLOYEE_ID = 'ID Dipendente';
lang.GLOBAL_LABEL_LEGAL_ENTITY = 'Entità legale';
lang.GLOBAL_LABEL_PRODUCT_VARIANTS = 'Varianti di prodotto';
lang.GLOBAL_LABEL_REFRESH_COMMON = (name) => `ricaricare ${name}`;
lang.GLOBAL_LABEL_TOTAL = 'Totale';
lang.GLOBAL_LABEL_SHAREPOINT = 'Sharepoint';
lang.GLOBAL_LABEL_PARTIAL_SIGN_OFF = '.Approvazione parziale';
lang.GLOBAL_LABEL_TITLE = 'Titolo';
lang.GLOBAL_LABEL_CLIENT_ID = 'Identificativo cliente';
lang.GLOBAL_LABEL_CLIENT_SECRET = 'Client Sercet';
lang.GLOBAL_LABEL_CONNECTION_ADDRESS = 'Indirizzo di connessione';
lang.GLOBAL_LABEL_ENABLE_BALANCE_DEDUCTION_BY_DEFAULT = 'Abilita la detrazione del saldo per impostazione predefinita';
lang.GLOBAL_LABEL_DETAILED_WEIGHING = 'Pesatura dettagliata';
lang.GLOBAL_LABEL_UPDATE = 'Aggiornamento';
lang.GLOBAL_FILTERKEY_DATE_ASC = 'Data di ASC';
lang.GLOBAL_FILTERKEY_DATE_DESC = 'Data di DESC';
lang.GLOBAL_FILTERKEY_STATUS_ASC = 'ASC Stato';
lang.GLOBAL_FILTERKEY_STATUS_DESC = 'DESC Stato';
lang.GLOBAL_FILTERKEY_ALL = 'Tutti';
lang.GLOBAL_FILTERKEY_ONLY_MY = 'Solo il mio';
lang.GLOBAL_FILTERKEY_ONLY_UNCLAIMED = 'Non rivendicato';
lang.GLOBAL_FILTERKEY_ONLY_UNSYNCED = 'Solo asincrono';
lang.GLOBAL_FILTERKEY_IN_PROGRESS = 'In corso';
lang.GLOBAL_FILTERKEY_IN_LAB = 'In laboratorio';
lang.GLOBAL_FILTERKEY_READY_FOR_TIER_2 = 'Pronto per Tier 2';
lang.GLOBAL_FILTERKEY_READY_FOR_SIGNOFF = 'Pronto per TRI';
lang.GLOBAL_FILTERKEY_SIGNED_OFF = 'TRI inserito';
lang.GLOBAL_FILTERKEY_COMPLETE = 'Libero';
lang.GLOBAL_FILTERKEY_PARTIALLY_SIGNED_OFF = 'Parzialmente firmato';
lang.GLOBAL_RADIOBUTTON_DRY = 'Asciutto';
lang.GLOBAL_RADIOBUTTON_RAIN = 'Pioggia';
lang.GLOBAL_RADIOBUTTON_SNOW = 'Neve';
lang.GLOBAL_RADIOBUTTON_DUMP = 'cumulo di rifiuti';
lang.GLOBAL_RADIOBUTTON_VAN = 'furgone';
lang.GLOBAL_RADIOBUTTON_FLATBED = 'Flatbed';
lang.GLOBAL_RADIOBUTTON_ROLL_OFF_BOX = '.Scatola di derivazione';
lang.GLOBAL_RADIOBUTTON_RAILCAR = 'Automotrice';
lang.GLOBAL_RADIOBUTTON_OTHER = 'Altro';
lang.GLOBAL_USERROLE_SUPERADMIN = 'Super Admin';
lang.GLOBAL_USERROLE_ADMIN = 'Admin';
lang.GLOBAL_USERROLE_WEIGHTBRIDGEOPERATOR = 'Pesa';
lang.GLOBAL_USERROLE_EXTERNALWEIGHTBRIDGE = '.Pesa esterna';
lang.GLOBAL_USERROLE_TIER3 = 'Livello 3';
lang.GLOBAL_USERROLE_TIER2 = 'Livello 2';
lang.GLOBAL_USERROLE_TIER1 = 'Livello 1';
lang.GLOBAL_USERROLE_MANAGER = 'Manager';
lang.GLOBAL_USERROLE_LAB = 'Laboratorio';
lang.GLOBAL_USERROLE_TRADER = 'Commerciale';
lang.GLOBAL_USERROLE_TRADER_MOBILE = 'Com.';
lang.GLOBAL_USERROLE_BACKOFFICE = '.Back Office';
lang.GLOBAL_USERROLE_DATAANALYZER = 'Analizzatore di dati';
lang.GLOBAL_LOGIN_HEADER = 'Accesso';
lang.GLOBAL_LOGIN_TITLE = 'Accedi al tuo account';
lang.GLOBAL_LOGOUT_SUCCESS_MSG = 'Sei stato disconnesso';
lang.GLOBAL_DATERANGEPICKER_START_DATE_PLH = 'Data d\'inizio';
lang.GLOBAL_DATERANGEPICKER_END_DATE_PLH = 'Data di fine';
lang.GLOBAL_RECOVERPASSWORD_HEADER = 'Impostare la password <br />';
lang.GLOBAL_RECOVERPASSWORD_UPDATE_MSG = 'Password è stata aggiornata';
lang.GLOBAL_RECOVERPASSWORD_SET_PASSWORD_BTN = 'Impostare la password';
lang.GLOBAL_RECOVERPASSWORD_CURRENT_PASSWORD = 'Password attuale';
lang.GLOBAL_RECOVERPASSWORD_NEW_PASSWORD = 'Nuova password';
lang.GLOBAL_RECOVERPASSWORD_REENTER_PASSWORD = 'Reinserire la nuova password';
lang.GLOBAL_FILE_SELECT_ERROR = '.Errore durante la selezione del file';
lang.GLOBAL_SERVERERROR_DEFAULT = 'Qualcosa è andato storto…';
lang.GLOBAL_SERVERERROR_EXTERNAL_SERVICE_ERROR = 'Fallimento in servizio esterno ...';
lang.GLOBAL_SERVERERROR_T2_CANNOT_UNFLAG_ADVISED_GOOD = 'T2 non può rimuovere il contrassegno consigliato bene.';
lang.GLOBAL_SERVERERROR_T2_CANNOT_UNFLAG_RECEIVED_GOOD = '.T2 non può rimuovere il flag come buono ricevuto.';
lang.GLOBAL_SERVERERROR_T2_CANNOT_ROLLBACK = 'T2 non può ripristinare il buono stato consigliato.';
lang.GLOBAL_SERVERERROR_TRADER_CANNOT_UNFLAG_ADVISED_GOOD = 'Trader non può rimuovere il contrassegno consigliato bene.';
lang.GLOBAL_SERVERERROR_ADMIN_CANNOT_ROLLBACK_FROM_CURRENT_AG_STATUS = '.L\'amministratore non può ripristinare consigliato buono dal suo stato attuale.';
lang.GLOBAL_SERVERERROR_T1_CANNOT_CLAIM = 'T1 non può rivendicare consigliato bene';
lang.GLOBAL_SERVERERROR_T1_CANNOT_UNLOAD = 'T1 non può scaricare consigliato bene';
lang.GLOBAL_SERVERERROR_T1_CANNOT_VERIFY = 'T1 non può verificare consigliato bene';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_ADVISED_GOOD_DELIVERY_NOT_SYNCED = 'Impossibile verificare consigliato bene, perché la consegna non è ancora sincronizzato';
lang.GLOBAL_SERVERERROR_CANNOT_COMPLETE_ADVISED_GOOD_NOT_UNLOADED = '.Impossibile completare consigliato bene, perché la consegna non è ancora scaricata';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_ADVISED_GOOD_FLAGGED_WHEN_SIGN_OFF = 'Segnalato consigliato bene non può essere pronto per il segno off';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_ADVISED_GOOD_NOT_READY_FOR_SIGN_OFF = '.Il bene consigliato non è pronto per la firma';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_ADVISED_GOOD_NOT_ALL_ITEMS_STOCK_CODED = '.Tutti gli articoli devono essere codificati prima che il bene consigliato possa essere firmato';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_ADVISED_GOOD_IF_VERIFIED_IT = '.Non puoi firmare bene se l\'hai già verificato';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_ADVISED_GOOD_NO_TARE_WEIGHT = '.Il bene consigliato non ha la tara inserita';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_LEVEL_SIGN_OFF_DISABLED_ON_THE_TENANT = '.L\'autorizzazione al buon livello consigliato è disabilitata per l\'inquilino';
lang.GLOBAL_SERVERERROR_CANNOT_UPDATE_ADVISED_GOOD_WHEN_DELIVERY_SIGNED_OFF = '.Impossibile aggiornare consigliato se la consegna è già stata firmata';
lang.GLOBAL_SERVERERROR_COMMENT_CANNOT_BE_UPDATED_IF_AG_IS_UNLOADED = '.Il commento non può essere aggiornato se AG è scaricato';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_ADVISED_GOOD_NO_AG_TARE_WEIGHT = '.In consigliato bene il peso di tara non è compilato in, consiglia pertanto bene non può essere verificata.';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_ADVISED_GOOD_NO_DELIVERY_TARE_WEIGHT = '.In consegna il peso di tara non è compilato in, consiglia pertanto bene non può essere verificata.';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_ADVISED_GOOD_STOCK_CODE_MISSING = 'Tutti i codici degli stock che deve essere riempito in buona prima consigliato può essere verificata';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_ADVISED_GOOD_ITEMS_IN_LAB = 'Ci sono ancora alcuni elementi in laboratorio';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_ALREADY_CLAIMED_BY = (fullname) => `Consigliato bene è già rivendicata da ${fullname}`;
lang.GLOBAL_SERVERERROR_CANNOT_ROLLBACK_ADVISED_GOOD_DELIVERY_SIGNED_OFF = 'Rollback non può essere fatta una consegna è firmato fuori';
lang.GLOBAL_SERVERERROR_CANNOT_ROLLBACK_ADVISED_GOOD_SIGNED_OFF = '.Non è possibile eseguire il rollback una volta che il bene consigliato è stato firmato';
lang.GLOBAL_SERVERERROR_CANNOT_DELETE_DELIVERY_LAB_SAMPLES_EXIST = '.La consegna con campioni di laboratorio non può essere cancellata';
lang.GLOBAL_SERVERERROR_CANNOT_DELETE_ADVISED_GOOD_LAB_SAMPLES_EXIST = 'Il prodotto con campioni di laboratorio non può essere cancellato';
lang.GLOBAL_SERVERERROR_CANNOT_DELETE_LAST_ADVISED_GOOD_IN_DELIVERY = '.La consegna include solo 1 bene consigliato. Elimina invece la consegna';
lang.GLOBAL_SERVERERROR_MAX_ALLOWED_11_RG_WITHIN_ONE_AG_IN_TEMPLATE = '.Il modello non supporta più di 11 merci ricevute all\'interno di un bene consigliato.';
lang.GLOBAL_SERVERERROR_MAX_ALLOWED_10_RG_WITHIN_ONE_AG = '.Massimo consentito 10 merci ricevute all\'interno di un bene consigliato.';
lang.GLOBAL_SERVERERROR_MAX_ALLOWED_19_RG_WITHIN_ONE_AG = '.Massimo consentito 19 merci ricevute all\'interno di un bene consigliato.';
lang.GLOBAL_SERVERERROR_CAMERA_TIMEOUT_WHEN_GETTING_IMAGE = 'Timeout durante il recupero di immagini dalla fotocamera';
lang.GLOBAL_SERVERERROR_CAMERA_CALIBRATION_REQUIRED = 'Si prega di calibrare fotocamera prima di utilizzarla';
lang.GLOBAL_SERVERERROR_CAMERA_TIMEOUT_WHEN_GETTING_OCR_RESULT = 'Timeout mentre ottenere risultato OCR';
lang.GLOBAL_SERVERERROR_ROLE_ADMIN_NOT_FOUND = 'ruolo di amministratore non esiste';
lang.GLOBAL_SERVERERROR_ROLE_APP_REGISTRATION_NOT_FOUND = '.Il ruolo AppRegistration non esiste';
lang.GLOBAL_SERVERERROR_ONLY_APP_REGISTRATION_EDIT_ALLOWED = '.È consentita solo la modifica di account di tipo AppRegistration';
lang.GLOBAL_SERVERERROR_WEAK_CLIENT_SECRET_ERROR = '.Client Secret richiede almeno 20 caratteri, una lettera maiuscola, una cifra, un carattere speciale';
lang.GLOBAL_SERVERERROR_CLIENT_ID_ALREADY_EXISTS = '.L\'ID cliente esiste già';
lang.GLOBAL_SERVERERROR_INVALID_APP_REGISTRATION_TITLE = '.Il titolo di registrazione dell\'app non è valido';
lang.GLOBAL_SERVERERROR_INVALID_APP_REGISTRATION_DESCRIPTION = '.La descrizione della registrazione dell\'app non è valida';
lang.GLOBAL_SERVERERROR_INVALID_CLIENT_SECRET_MIN_LENGTH_20 = '.Client Secret richiede almeno 20 caratteri';
lang.GLOBAL_SERVERERROR_CANNOT_REMOVE_TENANT_HAS_USERS = 'Impossibile rimuovere inquilino, perché alcuni utenti appartiene ad esso';
lang.GLOBAL_SERVERERROR_CANNOT_UPDATE_SEQUENCE_TENANT_HAS_DELIVERIES = '.Impossibile impostare la sequenza per il tenant perché ha già creato alcune consegne some';
lang.GLOBAL_SERVERERROR_CANNOT_UPDATE_LEGAL_ENTITY_HAS_DELIVERIES = '.Impossibile impostare l\'entità legale per l\'inquilino perché ha già creato alcune consegne';
lang.GLOBAL_SERVERERROR_SITE_IS_ALREADY_USED_BY_ANOTHER_TENANT = '.Questo sito non può essere utilizzato in quanto è già utilizzato da altri inquilini';
lang.GLOBAL_SERVERERROR_SITE_FIELD_IS_MANDATORY = '.Si prega di fornire il sito';
lang.GLOBAL_SERVERERROR_SHAREPOINT_UPLOAD_FOLDER_FIELD_IS_MANDATORY = '.Si prega di fornire la cartella SharePoint per il caricamento degli allegati';
lang.GLOBAL_SERVERERROR_SHAREPOINT_UPLOAD_FOLDER_DOES_NOT_EXIST = '.La cartella SharePoint selezionata non esiste';
lang.GLOBAL_SERVERERROR_SHAREPOINT_UPLOAD_FOLDER_NOT_SELECTED_FOR_TENANT = '.Cartella SharePoint per il caricamento degli allegati non selezionata per il tenant. Contatta il tuo SuperAdmin';
lang.GLOBAL_SERVERERROR_DOPS_NOT_ALLOWED_TO_UPLOAD_TO_THE_SELECTED_SITE = '.L\'applicazione dOps non è autorizzata a caricare file nel sito SharePoint selezionato. Contatta il tuo SuperAdmin';
lang.GLOBAL_SERVERERROR_FAILED_TO_UPDATE_METADATA_ON_SHAREPOINT = '.Errore servizio esterno: impossibile aggiornare i metadati degli allegati su SharePoint Online';
lang.GLOBAL_SERVERERROR_CANNOT_AUTHORIZE_TO_SHAREPOINT = '.Errore servizio esterno: impossibile accedere a SharePoint Online';
lang.GLOBAL_SERVERERROR_DELIVERY_ALREADY_SYNCED = 'Consegna non può essere aggiornato dopo che è sincronizzato';
lang.GLOBAL_SERVERERROR_CANNOT_SYNC_DELIVERY_NO_DELIVERY_NOTE_NUMBER = '.Il numero della bolla di consegna è obbligatorio per la sincronizzazione della consegna';
lang.GLOBAL_SERVERERROR_CANNOT_DELETE_ADVISED_GOOD_ALREADY_CLAIMED = 'Impossibile eliminare merci consigliate che sono già rivendicati';
lang.GLOBAL_SERVERERROR_CANNOT_SYNC_DELIVERY_NO_TARE_WEIGHT = '.Il peso della tara è obbligatorio per la sincronizzazione della consegna';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_SUB_ADVISED_GOOD_NO_RECEIVED_GOOD = 'Sub consigliato bene deve avere almeno un elemento ricevuto una buona';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_ADVISED_GOOD_NO_SUBS = 'Consigliato bene deve contenere almeno un elemento in consigliato buona ripartizione';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_DELIVERY_NO_ADVISED_GOOD = 'Consegna deve contenere almeno un consigliato Good item';
lang.GLOBAL_SERVERERROR_CANNOT_SAVE_TARE_WEIGHT_ADVISED_GOOD_IS_CLAIMED = 'Impossibile salvare peso di tara fino a quando consigliato bene è rivendicata';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_DELIVERY_NOT_ALL_ITEMS_STOCK_CODED = 'Tutti gli articoli deve essere codificato magazzino prima della consegna può essere firmato-off';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_DELIVERY_NOT_ALL_LAB_ITEMS_COMPLETED = 'Tutti gli elementi di laboratorio deve essere completata prima della consegna può essere firmato-off';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_DELIVERY_NOT_ALL_ITEMS_HAVE_TARE_WEIGHT = '.Ci sono alcuni beni consigliate che non hanno peso di tara compilati';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_NOT_ALL_ITEMS_HAVE_GROSS_WEIGHT = '.Alcune merci ricevute non hanno il peso lordo compilato';
lang.GLOBAL_SERVERERROR_CANNOT_SYNC_DELIVERY_NOT_ALL_ITEMS_HAVE_EWC_CODE = '.Esistono alcuni articoli consigliati che non hanno compilato il codice CER';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_DELIVERY_NOT_YET_SYNCED_AT_WEIGHTBRIDGE = '.La consegna deve essere sincronizzata prima della firma';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_ADVISED_GOOD_DELIVERY_NOT_YET_SYNCED_AT_WEIGHTBRIDGE = '.Impossibile firmare consigliato, la consegna deve essere sincronizzata';
lang.CANNOT_SIGN_OFF_DELIVERY_AG_NOT_READY_FOR_SIGN_OFF = '.Tutte le merci consigliate devono essere pronte per la firma prima che la consegna possa essere firmata';
lang.GLOBAL_SERVERERROR_CANNOT_COMPLETE_DELIVERY_NOT_ALL_ITEMS_HAVE_TARE_WEIGHT = '.Impossibile completare la consegna fino a quando sono forniti tutti i pesi di tara';
lang.GLOBAL_SERVERERROR_CANNOT_COMPLETE_DELIVERY_NOT_SIGNED_OFF = 'Impossibile completare la consegna che non è firmato fuori.';
lang.GLOBAL_SERVERERROR_CANNOT_RESYNC_DELIVERY_NOT_YET_SYNCED_AT_WEIGHTBRIDGE = 'Consegna non può essere ri-sincronizzato perché non è ancora sincronizzato alla pesa a ponte';
lang.GLOBAL_SERVERERROR_CANNOT_RESYNC_SORTING_NOT_YET_SIGNED_OFF = 'L\'ordinamento non può essere ri-sincronizzato perché non è ancora firmato off';
lang.GLOBAL_SERVERERROR_MATERIAL_ALREADY_EXISTS = 'Materiale con lo stesso codice esiste già';
lang.GLOBAL_SERVERERROR_MATERIAL_IN_USE_BY_DELIVERY = (grn) => `Il materiale è in uso da parte di consegna ${grn}`;
lang.GLOBAL_SERVERERROR_CANNOT_EDIT_ADVISED_GOOD_UNTIL_CLAIMED = 'Non è possibile modificare i beni consigliate senza pretendere in primo luogo';
lang.GLOBAL_SERVERERROR_CANNOT_ADD_LAB_ANALYSIS_FOR_COMPLETED_ADVISED_GOOD = 'Impossibile aggiungere le analisi di laboratorio per elementi del report di ordinamento completati';
lang.GLOBAL_SERVERERROR_STOCK_CODE_INVALID = (stockCode) => `Fornito magazzino ${stockCode} interno non è valido`;
lang.GLOBAL_SERVERERROR_USER_ROLE_NOT_FOUND = (userRole) => `.ID ruolo utente fornito ${userRole} non trovato`;
lang.GLOBAL_SERVERERROR_CANNOT_ADD_LAB_ANALYSIS_FOR_NOT_CLAIMED_ADVISED_GOOD = 'Impossibile aggiungere le analisi di laboratorio per non aver sostenuto l\'ordinamento elementi del report';
lang.GLOBAL_SERVERERROR_QR_CODE_IMAGE_GENERATION_ERROR = 'Si è verificata un\'eccezione durante la generazione l\'immagine del codice QR';
lang.GLOBAL_SERVERERROR_CANNOT_TRANSFER_RECEIVED_GOOD_NULL_WEIGHT = 'Ricevuto buon peso non può essere NULL quando si trasferisce';
lang.GLOBAL_SERVERERROR_CANNOT_TRANSFER_RECEIVED_GOOD_MAX_WEIGHT_EXCEEDED = 'Peso massimo al trasferimento è tutto il peso del ricevuto una buona';
lang.GLOBAL_SERVERERROR_CANNOT_TRANSFER_ADVISED_GOOD_NULL_WEIGHT = 'buon peso comunicato deve essere impostato prima del trasferimento';
lang.GLOBAL_SERVERERROR_CANNOT_TRANSFER_ADVISED_GOOD_MAX_WEIGHT_EXCEEDED = 'Peso massimo di trasferimento non può essere maggiore di buon peso consigliato';
lang.GLOBAL_SERVERERROR_CANNOT_TRANSFER_RECEIVED_GOOD_WITH_NEGATIVE_WEIGHT = 'Impossibile trasferire il bene ricevuto con peso negativo';
lang.GLOBAL_SERVERERROR_CANNOT_TRANSFER_FULL_RECEIVED_GOOD = 'Impossibile trasferire l\'intero bene ricevuto';
lang.GLOBAL_SERVERERROR_CANNOT_TRANSFER_FULL_ADVISED_GOOD = 'Non è possibile trasferire l\'intero bene consigliato';
lang.GLOBAL_SERVERERROR_CAN_NOT_TRANSFER_RECEIVED_GOOD_IF_ONLY_ONE_IN_ADVISED_GOOD = 'Non è possibile trasferire il bene ricevuto se solo un bene consigliato';
lang.GLOBAL_SERVERERROR_CANNOT_CREATE_SUPER_ADMIN = 'Impossibile assegnare il ruolo super amministratore per l\'utente';
lang.GLOBAL_SERVERERROR_WEAK_PASSWORD_ERROR = '.La password richiede almeno 10 caratteri, una lettera maiuscola, una cifra, un carattere speciale';
lang.GLOBAL_SERVERERROR_CANNOT_DELETE_ADMIN_IF_ADMIN = 'Non è possibile eliminare altri amministratori';
lang.GLOBAL_SERVERERROR_UNDOCUMENTED_ERROR = 'errore di Undocumented';
lang.GLOBAL_SERVERERROR_ERROR_SENDING_EMAIL = 'Errore durante l\'invio di e-mail';
lang.GLOBAL_SERVERERROR_ERROR_WHILE_PROCESSING_REQUEST = 'Erro mentre l\'elaborazione della richiesta';
lang.GLOBAL_SERVERERROR_FILE_NOT_FOUND = 'File non trovato';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_NOT_FOUND = 'Consigliato buona non trovato';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_ATTACHMENT_NOT_FOUND = 'Consigliato buon attacco non trovato';
lang.GLOBAL_SERVERERROR_CONTAMINATION_NOT_FOUND = 'La contaminazione non trovato';
lang.GLOBAL_SERVERERROR_SUB_ADVISED_GOOD_NOT_FOUND = 'Sub consigliava bene non trovato';
lang.GLOBAL_SERVERERROR_RECEIVED_GOOD_NOT_FOUND = 'Ricevuto buona non trovato';
lang.GLOBAL_SERVERERROR_RECEIVED_GOOD_DESCRIPTION_MAX_LENGTH_79 = '.Ricevuto buona descrizione non può essere più lungo di 79 caratteri';
lang.GLOBAL_SERVERERROR_CAMERA_NOT_FOUND = 'Fotocamera non trovata';
lang.GLOBAL_SERVERERROR_CAMERA_NOT_ACTIVE = 'La fotocamera non attiva';
lang.GLOBAL_SERVERERROR_TENANT_NOT_FOUND = 'Inquilino non trovato';
lang.GLOBAL_SERVERERROR_ADMIN_NOT_FOUND = 'Admin non trovato';
lang.GLOBAL_SERVERERROR_USER_NOT_FOUND = 'Utente non trovato';
lang.GLOBAL_SERVERERROR_LOCATION_NOT_FOUND = 'Posizione non trovata';
lang.GLOBAL_SERVERERROR_LOCATION_ALREADY_EXISTS = 'Location esiste già';
lang.GLOBAL_SERVERERROR_DELIVERY_NOT_FOUND = 'Consegna non trovata';
lang.GLOBAL_SERVERERROR_DELIVERY_ATTACHMENT_NOT_FOUND = 'attacco di consegna non trovato';
lang.GLOBAL_SERVERERROR_SUPPLIER_NOT_FOUND = 'Fornitore non trovato';
lang.GLOBAL_SERVERERROR_SUPPLIER_TYPE_NOT_FOUND = 'Tipo di fornitore non trovato';
lang.GLOBAL_SERVERERROR_ROAD_HAULIER_NOT_FOUND = 'autotrasportatore non trovato';
lang.GLOBAL_SERVERERROR_MATERIAL_NOT_FOUND = 'Il materiale non trovato';
lang.GLOBAL_SERVERERROR_LAB_INPUT_TYPE_NOT_FOUND = 'Lab tipo di ingresso non trovato';
lang.GLOBAL_SERVERERROR_LAB_INPUT_TYPE_NOT_FOUND_FOR_WORKFLOW = 'tipo di ingresso laboratorio non trovate in workflow';
lang.GLOBAL_SERVERERROR_LAB_INPUT_NOT_FOUND = 'Ingresso Lan non trovato';
lang.GLOBAL_SERVERERROR_LAB_STATUS_NOT_SUPPORTED = 'Stato Lab non supportato';
lang.GLOBAL_SERVERERROR_LAB_STATUS_NOT_FOUND_FOR_WORKFLOW = 'Stato Lab non trovato per il workflow';
lang.GLOBAL_SERVERERROR_STOCK_ITEM_NOT_FOUND = 'Articolo di magazzino non trovato';
lang.GLOBAL_SERVERERROR_STOCK_ITEM_ALREADY_EXISTS = '.L\'articolo in magazzino esiste già';
lang.GLOBAL_SERVERERROR_STOCK_ITEM_CODE_ALLOWED_LENGTH_5 = '.La lunghezza del codice dell\'articolo disponibile deve essere di 5 cifre';
lang.GLOBAL_SERVERERROR_PRINTER_NOT_FOUND = 'Stampante non trovata';
lang.GLOBAL_SERVERERROR_SCALE_NOT_FOUND = 'Scala non trovato';
lang.GLOBAL_SERVERERROR_PRINT_TEMPLATE_NOT_FOUND = 'modello di stampa non trovato';
lang.GLOBAL_SERVERERROR_PRODUCT_FORM_NOT_FOUND = '.Modulo prodotto non trovato';
lang.GLOBAL_SERVERERROR_PRODUCT_FORM_NOT_RELATED_TO_STOCK_ITEM = '.Il modulo del prodotto fornito non è correlato all\'articolo in stock fornito';
lang.GLOBAL_SERVERERROR_PRODUCT_QUALITY_NOT_FOUND = '.Qualità del prodotto non trovata';
lang.GLOBAL_SERVERERROR_PRODUCT_VARIANT_NOT_FOUND = '.Variante del prodotto non trovata';
lang.GLOBAL_SERVERERROR_PRODUCT_VARIANTS_ARE_REQUIRED_FOR_SIGN_OFF = '.Le varianti di prodotto sono necessarie per l\'approvazione';
lang.GLOBAL_SERVERERROR_USERNAME_ALREADY_EXISTS = 'Il nome utente esiste già';
lang.GLOBAL_SERVERERROR_CANNOT_DELETE_YOURSELF = 'Impossibile eliminare te stesso';
lang.GLOBAL_SERVERERROR_INVALID_USERNAME_OR_PASSWORD = 'Username o password non validi';
lang.GLOBAL_SERVERERROR_PASSWORD_HAS_ALREADY_BEEN_USED_BEFORE = '.Scegli una password diversa. Questo è già stato utilizzato prima';
lang.GLOBAL_SERVERERROR_CLIENT_SECRET_EXPIRED = '.Il segreto client corrente è stato utilizzato per 2 anni ed è scaduto. Si prega di contattare il superamministratore';
lang.GLOBAL_SERVERERROR_INVALID_EMAIL = 'Si prega di fornire e-mail valido';
lang.GLOBAL_SERVERERROR_INVALID_USERNAME = 'Si prega di fornire il nome utente';
lang.GLOBAL_SERVERERROR_INVALID_PASSWORD = 'Si prega di fornire la password';
lang.GLOBAL_SERVERERROR_INVALID_PASSWORD_MIN_LENGTH_10 = 'lunghezza minima della password è di 10 caratteri';
lang.GLOBAL_SERVERERROR_INVALID_CLIENT_ID = '.ID cliente non valido';
lang.GLOBAL_SERVERERROR_INVALID_CLIENT_SECRET = '.Segreto client non valido';
lang.GLOBAL_SERVERERROR_INVALID_FIRSTNAME = 'Si prega di fornire Cognome';
lang.GLOBAL_SERVERERROR_INVALID_LASTNAME = 'Fornire cognome';
lang.GLOBAL_SERVERERROR_INVALID_USER_ROLES = 'Si prega di fornire ruoli utente';
lang.GLOBAL_SERVERERROR_INVALID_USER_TENANTS = '.Fornisci tenant utente';
lang.GLOBAL_SERVERERROR_INVALID_EXTERNAL_EMPLOYEE_ID = '.L\'ID dipendente esterno deve contenere almeno 6 simboli';
lang.GLOBAL_SERVERERROR_EXTERNAL_EMPLOYEE_ID_REQUIRED = '.Il campo ID dipendente esterno è obbligatorio';
lang.GLOBAL_SERVERERROR_EXTERNAL_EMPLOYEE_ID_NOT_FOUND = '.Dipendente esterno con ID fornito non trovato';
lang.GLOBAL_SERVERERROR_EXTERNAL_EMPLOYEE_NOT_AVAILABLE_FOR_TENANT = (tenantName) => `.L\'ID dipendente selezionato non è idoneo a lavorare sul tenant: ${tenantName}`;
lang.GLOBAL_SERVERERROR_USER_MUST_HAVE_ONLY_ONE_DEFAULT_ROLE = '.Fornisci un solo ruolo predefinito per l\'utente';
lang.GLOBAL_SERVERERROR_USER_MUST_HAVE_ONLY_ONE_DEFAULT_TENANT = '.Fornisci un solo tenant predefinito per l\'utente';
lang.GLOBAL_SERVERERROR_USER_ACTIVE_ROLE_MUST_MATCH_ONE_OF_USER_ROLES = '.Il ruolo attivo dell\'utente deve corrispondere a uno dei ruoli utente esistenti';
lang.GLOBAL_SERVERERROR_USER_HAS_NO_PERMISSION_TO_ACCESS_THE_TENANT = '.Non hai l\'autorizzazione per accedere al tenant selezionato';
lang.GLOBAL_SERVERERROR_ONLY_SUPER_ADMIN_CAN_CREATE_OR_EDIT_ADMIN = '.Solo il super amministratore può creare o modificare l\'utente con il ruolo "Admin"';
lang.GLOBAL_SERVERERROR_USER_CANNOT_BE_TRADER_AND_BACKOFFICE_SIMULTANEOUSLY = '.L\'utente non può avere ruoli Trader e BackOffice contemporaneamente';
lang.GLOBAL_SERVERERROR_USER_WITH_ROLE_DATAANALYZER_CANNOT_HAVE_MULTIPLE_ROLES = '.L\'utente con ruolo DataAnalyzer non può avere più ruoli';
lang.GLOBAL_SERVERERROR_MULTIPLE_TENANTS_CAN_BE_ASSIGNED_FOR_TRADER_BACKOFFICE_OR_ADMIN = '.È possibile assegnare più tenant solo se l\'utente ha il ruolo Trader, BackOffice o Admin';
lang.GLOBAL_SERVERERROR_INVALID_NAME = 'Si prega di fornire il nome';
lang.GLOBAL_SERVERERROR_ACCESS_DENIED = 'L\'accesso è negato';
lang.GLOBAL_SERVERERROR_SESSION_NOT_FOUND = 'Sessione non trovato';
lang.GLOBAL_SERVERERROR_SESSION_EXPIRED = 'Sessione scaduta';
lang.GLOBAL_SERVERERROR_TRY_AGAIN_IN_ONE_MINUTE = '.Riprova tra 1 minuto';
lang.GLOBAL_SERVERERROR_STOCK_ITEM_NAME_MISSING = 'Si prega di fornire nome articolo di riserva';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_DELIVERY_IF_VERIFIED_ADVISED_GOOD = 'Non si può firmare la consegna se già verificato uno dei beni consigliate';
lang.GLOBAL_SERVERERROR_SUPPLIER_CODE_ALREADY_EXISTS = 'Fornitore con codice fornito esiste già';
lang.GLOBAL_SERVERERROR_SUPPLIER_NAME_MISSING = 'Si prega di fornire nome del fornitore';
lang.GLOBAL_SERVERERROR_SUPPLIER_CODE_MISSING = 'Si prega di fornire il codice fornitore';
lang.GLOBAL_SERVERERROR_PROVIDE_FLAGGED_STATUS = 'Si prega di fornire lo stato contrassegnato';
lang.GLOBAL_SERVERERROR_PROVIDE_DESCRIPTION = 'Si prega di fornire la descrizione';
lang.GLOBAL_SERVERERROR_RECEIVED_GOOD_DESCRIPTION_MAX_LENGTH_255 = '.Ricevuto buona descrizione non può essere più lungo di 255 caratteri';
lang.GLOBAL_SERVERERROR_PROVIDE_MATERIAL_INFORMATION = 'Si prega di fornire informazioni rilevanti';
lang.GLOBAL_SERVERERROR_PROVIDE_MATERIAL_ID = 'Si prega di fornire ID materiale';
lang.GLOBAL_SERVERERROR_PROVIDE_MATERIAL_WEIGHT = 'Si prega di fornire peso del materiale';
lang.GLOBAL_SERVERERROR_PROVIDE_SUB_ADVISED_GOOD_ID = 'Si prega di fornire sub consigliato buona id';
lang.GLOBAL_SERVERERROR_PROVIDE_VALUE_FOR_P1 = 'Si prega di fornire un valore per P1';
lang.GLOBAL_SERVERERROR_PROVIDE_VALUE_FOR_P2 = 'Si prega di fornire un valore per P2';
lang.GLOBAL_SERVERERROR_PROVIDE_VALUE_FOR_P3 = 'Si prega di fornire un valore per p3';
lang.GLOBAL_SERVERERROR_PROVIDE_VALUE_FOR_P4 = 'Si prega di fornire un valore per p4';
lang.GLOBAL_SERVERERROR_PROVIDE_CONTRAST = 'Si prega di fornire contrasto';
lang.GLOBAL_SERVERERROR_PROVIDE_DISPLAY_TYPE = 'Si prega di fornire il tipo di visualizzazione';
lang.GLOBAL_SERVERERROR_PROVIDE_CAMERA_NAME = 'Si prega di fornire il nome della telecamera';
lang.GLOBAL_SERVERERROR_PROVIDE_CAMERA_PLACE = 'Si prega di fornire posto fotocamera';
lang.GLOBAL_SERVERERROR_PROVIDE_CAMERA_UNIQUE_ID = 'Si prega di fornire fotocamera ID univoco';
lang.GLOBAL_SERVERERROR_PROVIDE_SUPPLIER = 'Si prega di fornire il fornitore';
lang.GLOBAL_SERVERERROR_PROVIDE_ROAD_HAULIER = 'Si prega di fornire autotrasportatore';
lang.GLOBAL_SERVERERROR_PROVIDE_DEPARTMENT = 'si prega di fornire dipartimento';
lang.GLOBAL_SERVERERROR_REMARKS_MAX_LENGTH_30 = 'Osservazioni possono essere max 30 caratteri';
lang.GLOBAL_SERVERERROR_PROVIDE_VEHICLE_REGISTRATION_NUMBER = 'Si prega di fornire il numero di immatricolazione del veicolo';
lang.GLOBAL_SERVERERROR_PROVIDE_STOCK_ITEM = 'Si prega di fornire articolo di riserva';
lang.GLOBAL_SERVERERROR_PROVIDE_ADVISED_GOOD_DESCRIPTION = 'Si prega di fornire una descrizione consigliato';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_DESCRIPTION_MAX_LENGTH_30 = 'Consigliato buona descrizione non può essere più lungo di 30 caratteri';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_DESCRIPTION_MAX_LENGTH_50 = 'Consigliato buona descrizione non può essere più lungo di 50 caratteri';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_DESCRIPTION_MAX_LENGTH_100 = 'Consigliato buona descrizione non può essere più lungo di 100 caratteri';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_COMMENT_MAX_LENGTH_100 = 'Il commento non può essere più lungo di 100 caratteri';
lang.GLOBAL_SERVERERROR_PROVIDE_WB_TICKET_NUMBER = 'Si prega di fornire un numero di ticket pesa a ponte';
lang.GLOBAL_SERVERERROR_PROVIDE_LOCATION = 'Si prega di fornire una posizione';
lang.GLOBAL_SERVERERROR_PROVIDE_MATERIAL_CODE = 'Si prega di fornire il codice materiale';
lang.GLOBAL_SERVERERROR_PROVIDE_MATERIAL_NAME = 'Si prega di fornire il nome del materiale';
lang.GLOBAL_SERVERERROR_PROVIDE_PRINTER_NAME = 'Si prega di fornire il nome della stampante';
lang.GLOBAL_SERVERERROR_PROVIDE_PRINTER_IP = 'Si prega di fornire IP della stampante';
lang.GLOBAL_SERVERERROR_PROVIDE_PRINTER_MODEL = 'Si prega di fornire modello di stampante';
lang.GLOBAL_SERVERERROR_PROVIDE_PRINTER_ADDRESS = '.Si prega di fornire l\'indirizzo di connessione della stampante';
lang.GLOBAL_SERVERERROR_PRINTER_DESCRIPTION_MAX_LENGTH_255 = '.La descrizione può essere lunga al massimo 255 caratteri';
lang.GLOBAL_SERVERERROR_PRINTER_ADDRESS_MAX_LENGTH_255 = '.L\'indirizzo può essere lungo al massimo 255 caratteri';
lang.GLOBAL_SERVERERROR_PRINTER_NAME_MAX_LENGTH_50 = '.Il nome può contenere al massimo 255 caratteri';
lang.GLOBAL_SERVERERROR_DOCUMENT_TYPE_NOT_FOUND = '.Tipo di documento non trovato';
lang.GLOBAL_SERVERERROR_PROVIDE_LASERNET_REPORT_NAME = '.Fornisci il nome del rapporto Lasernet';
lang.GLOBAL_SERVERERROR_LASERNET_REPORT_NAME_MAX_LENGTH_255 = '.Il nome del rapporto Lasernet può contenere al massimo 255 caratteri';
lang.GLOBAL_SERVERERROR_PROVIDE_DEFAULT_NUMBER_OF_COPIES = '.Si prega di fornire il numero predefinito di copie';
lang.GLOBAL_SERVERERROR_PROVIDE_PRINTER_ID = '.Si prega di fornire l\'ID della stampante';
lang.GLOBAL_SERVERERROR_PRINTER_ALREADY_EXISTS = '.La stampante con il nome fornito esiste già';
lang.GLOBAL_SERVERERROR_PRINTER_CONFIGURATION_NOT_FOUND = '.Configurazione della stampante non trovata';
lang.GLOBAL_SERVERERROR_PRINTER_CONFIGURATION_ALREADY_EXISTS = '.La configurazione della stampante con i parametri forniti esiste già';
lang.GLOBAL_SERVERERROR_PROVIDE_NUMBER_OF_COPIES = '.Si prega di fornire il numero di copie';
lang.GLOBAL_SERVERERROR_PROVIDE_WEIGHT_TO_TRANSFER = 'Si prega di fornire il peso per il trasferimento';
lang.GLOBAL_SERVERERROR_PROVIDE_STATUS = 'Si prega di fornire nuovo status';
lang.GLOBAL_SERVERERROR_PROVIDE_SCALE_NAME = 'Si prega di fornire nome della bilancia';
lang.GLOBAL_SERVERERROR_PROVIDE_SCALE_IP = 'Si prega di fornire ip scala';
lang.GLOBAL_SERVERERROR_PROVIDE_SCALE_PORT = 'Si prega di fornire porta scala';
lang.GLOBAL_SERVERERROR_PROVIDE_DELIVERY_TYPE = '.Si prega di fornire il tipo di consegna';
lang.GLOBAL_SERVERERROR_PROVIDE_WEATHER_CONDITION = '.Si prega di fornire condizioni meteorologiche';
lang.GLOBAL_SERVERERROR_PROVIDE_TRANSACTION_TYPE = '.Si prega di fornire il tipo di transazione';
lang.GLOBAL_SERVERERROR_PROVIDE_PROCESSING_PRICE = '.Si prega di fornire il prezzo di elaborazione';
lang.GLOBAL_SERVERERROR_PROCESSING_PRICE_NOT_FOUND = '.Prezzo di elaborazione non trovato';
lang.GLOBAL_SERVERERROR_IMAGE_RESOLUTION_TOO_HIGH = 'La risoluzione dell\'immagine è troppo alta';
lang.GLOBAL_SERVERERROR_ONLY_PDF_FILES_AND_IMAGES_ARE_ALLOWED = '.Sono consentiti solo file e immagini PDF come allegati';
lang.GLOBAL_SERVERERROR_GRN_CAN_NOT_BE_EMPTY = 'GRN non può essere vuoto';
lang.GLOBAL_SERVERERROR_CANNOT_DELETE_ITEM_WITH_TRANSFER_RELATION = (transfers) => `Impossibile eliminare le merci che sono legati a trasferire gli oggetti: ${transfers}`;
lang.GLOBAL_SERVERERROR_UNSUPPORTED_ACTION = 'azione non supportata';
lang.GLOBAL_SERVERERROR_THERE_ARE_NO_D365_TENANTS_AVAILABLE = '.Non sono disponibili tenant D365 su questo server';
lang.GLOBAL_SERVERERROR_CANNOT_CREATE_LAB_INPUT_SAME_LAB_INPUT_TYPE_ALREADY_EXISTS = 'Impossibile creare ingresso laboratorio perché un altro ingresso laboratorio dello stesso tipo esiste già';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_DEDUCTION_NO_WEIGHT = 'Comunicato peso buona deduzione non può essere vuoto';
lang.GLOBAL_SERVERERROR_MACHINE_NOT_FOUND = 'La macchina non trovata';
lang.GLOBAL_SERVERERROR_TARGET_BATCH_NOT_FOUND = 'lotto di destinazione non trovato';
lang.GLOBAL_SERVERERROR_RECEIVED_GOOD_WEIGHT_MISSING = (rgIdentifier) => `Si prega di fornire Peso di: ${rgIdentifier}`;
lang.GLOBAL_SERVERERROR_MACHINE_VALUE_MISSING = (rgIdentifier) => `Si prega di fornire Macchina in: ${rgIdentifier}`;
lang.GLOBAL_SERVERERROR_WI_DONE_VALUE_MISSING = (rgIdentifier) => `Si prega di fornire WI Fatto in: ${rgIdentifier}`;
lang.GLOBAL_SERVERERROR_BULK_DENSITY_VALUE_MISSING = (rgIdentifier) => `Si prega di fornire Densità apparente in: ${rgIdentifier}`;
lang.GLOBAL_SERVERERROR_MATERIAL_DESCRIPTION_VALUE_MISSING = (rgIdentifier) => `Si prega di fornire Descrizione materiale in: ${rgIdentifier}`;
lang.GLOBAL_SERVERERROR_FOUND_QUALITY_VALUE_MISSING = (rgIdentifier) => `Si prega di fornire qualità Trovato in: ${rgIdentifier}`;
lang.GLOBAL_SERVERERROR_MAIN_TYPE_VALUE_MISSING = (rgIdentifier) => `Si prega di fornire il tipo principale di: ${rgIdentifier}`;
lang.GLOBAL_SERVERERROR_TARGET_BATCH_VALUE_MISSING = (rgIdentifier) => `Si prega di fornire Batch Target in: ${rgIdentifier}`;
lang.GLOBAL_SERVERERROR_TENANT_NAME_VALUE_MISSING = 'Si prega di fornire il nome dell\'inquilino';
lang.GLOBAL_SERVERERROR_TENANT_CODE_VALUE_MISSING = 'Si prega di fornire il codice inquilino';
lang.GLOBAL_SERVERERROR_TENANT_LOCATION_VALUE_MISSING = 'Si prega di fornire inquilino codice di posizione';
lang.GLOBAL_SERVERERROR_TENANT_LOCATION_MAX_CHARS = 'Codice locazione inquilino può essere al massimo 10 caratteri';
lang.GLOBAL_SERVERERROR_TENANT_GRN_PREFIX_VALUE_MISSING = 'Si prega di fornire inquilino prefisso GRN';
lang.GLOBAL_SERVERERROR_TENANT_CURRENT_GRN_VALUE_MISSING = 'Si prega di fornire un valore GRN corrente per inquilino';
lang.GLOBAL_SERVERERROR_TENANT_WORKFLOW_VALUE_MISSING = 'Si prega di fornire il flusso di lavoro inquilino';
lang.GLOBAL_SERVERERROR_TENANT_TIMEZONE_VALUE_MISSING = 'Si prega di fornire fuso orario inquilino';
lang.GLOBAL_SERVERERROR_TENANT_REGION_VALUE_MISSING = 'Si prega di fornire il codice regione inquilino';
lang.GLOBAL_SERVERERROR_TENANT_REGION_MAX_CHARS = 'Codice di zona inquilino può essere al massimo 10 caratteri';
lang.GLOBAL_SERVERERROR_TENANT_LEGAL_ENTITY_VALUE_MISSING = '.Si prega di fornire l\'entità giuridica dell\'inquilino';
lang.GLOBAL_SERVERERROR_ONLY_ATTACHMENT_OWNER_OR_ADMIN_CAN_DELETE_ATTACHMENT = 'Solo il proprietario o l\'amministratore dell\'allegato può eliminare l\'allegato';
lang.GLOBAL_SERVERERROR_INTEGRATION_ENDPOINT_CONFIGURATION_MISSING = 'Manca la configurazione dell\'endpoint di integrazione. Si prega di contattare l\'amministratore.';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_UNCLAIM_ONLY_IN_STATUS_CLAIMED = 'Il bene consigliato non può essere reclamato solo se nello stato CLAIMED';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_UNCLAIM_ONLY_BY_OWNER_OR_ADMIN = 'Il bene consigliato può essere reclamato solo dalla persona che lo ha rivendicato o dall\'amministratore';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_UNCLAIM_ONLY_IF_NO_LAB_INPUTS = 'Il bene consigliato può essere annullato solo se non sono stati creati input di laboratorio';
lang.GLOBAL_SERVERERROR_PACKAGING_ALREADY_EXISTS = 'Il packaging esiste già';
lang.GLOBAL_SERVERERROR_PACKAGING_NOT_FOUND = 'Imballaggio non trovato';
lang.GLOBAL_SERVERERROR_SIZES_SUM_IS_NOT_100_PERCENT = 'La somma dei campi "Dimensioni" di contaminazione deve essere del 100%';
lang.GLOBAL_SERVERERROR_PROCESSING_TIME_NOT_FOUND = 'Tempo di elaborazione non trovato';
lang.GLOBAL_SERVERERROR_CONTAMINATION_VALUE_MISSING = (rgIdentifier) => `Fornisci la contaminazione in: ${rgIdentifier}`;
lang.GLOBAL_SERVERERROR_INVALID_CODE = 'Si prega di fornire il codice';
lang.GLOBAL_SERVERERROR_PROVIDE_ORIGIN = 'Si prega di fornire l\'origine della consegna';
lang.GLOBAL_SERVERERROR_ORIGIN_NOT_FOUND = 'Origine non trovata';
lang.GLOBAL_SERVERERROR_ORIGIN_ALLOWED_LENGTH_35 = '.La lunghezza di origine dovrebbe essere di 35 lettere';
lang.GLOBAL_SERVERERROR_EWC_NOT_FOUND = 'CER non trovata';
lang.GLOBAL_SERVERERROR_EWC_ALREADY_EXISTS = '.CER esiste già';
lang.GLOBAL_SERVERERROR_GRN_ALREADY_EXISTS = '.GRN esiste già';
lang.GLOBAL_SERVERERROR_CANNOT_EXPORT_DELIVERY_NOT_YET_SIGNED_OFF = 'La consegna non può essere esportata perché non è ancora stata firmata';
lang.GLOBAL_SERVERERROR_CANNOT_EXPORT_DELIVERY_LIST_NEEDS_TO_BE_FILTERED = '.Utilizza un filtro per ridurre il numero di consegne da esportare. La lista di consegna selezionata è troppo lunga. ';
lang.GLOBAL_SERVERERROR_MEASUREMENT_NOT_FOUND = 'Misura non trovata';
lang.GLOBAL_SERVERERROR_SPECTRO_ANALYSIS_NOT_FOUND = '.Analisi Spettro non trovata.';
lang.GLOBAL_SERVERERROR_PROVIDE_MANDATORY_FIELDS = (joinedlabelslist) => `.Si prega di fornire il: ${joinedlabelslist}`;
lang.GLOBAL_SERVERERROR_INVALID_USER_CODE_LENGTH = (allowedLength) => `.La lunghezza del codice utente deve essere di ${allowedLength} lettere`;
lang.GLOBAL_SERVERERROR_USER_WITH_THIS_CODE_ALREADY_EXISTS = '.L\'utente con questo codice esiste già nel tenant';
lang.GLOBAL_SERVERERROR_PROVIDE_DELIVERY_CREATION_DATE = '.Fornisci la data di creazione della consegna';
lang.GLOBAL_SERVERERROR_PROVIDE_ADVISED_GOOD_LINE_NUMBER = '.Fornire un buon numero di riga consigliato';
lang.GLOBAL_SERVERERROR_PROVIDE_TRADER_CODE = '.Si prega di fornire il codice commerciante';
lang.GLOBAL_SERVERERROR_PROVIDE_ADVISED_NET_WEIGHT = '.Fornire peso netto consigliato';
lang.GLOBAL_SERVERERROR_DELIVERY_GRN_ALREADY_COMPLETED_CANNOT_UPDATE = (grn) => `.Consegna ${grn} già completata e non può essere aggiornata`;
lang.GLOBAL_SERVERERROR_DELIVERY_GRN_ALREADY_SIGNED_OFF_CANNOT_UPDATE = (grn) => `.Consegna ${grn} già firmata e non può essere aggiornata`;
lang.GLOBAL_SERVERERROR_FILE_IS_EMPTY = '.Il file è vuoto';
lang.GLOBAL_SERVERERROR_CONTRACT_IMPORT_FAILED = '.Impossibile importare il contratto ';
lang.GLOBAL_SERVERERROR_NUMBER_OF_COLUMNS_DOES_NOT_CORRESPOND_THE_TEMPLATE = '.Il numero di colonne non corrisponde al modello';
lang.GLOBAL_SERVERERROR_ANOTHER_COLUMN_EXPECTED_IN_PLACE_OF_PROVIDED = '.L\'ordine delle colonne non corrisponde al modello';
lang.GLOBAL_SERVERERROR_YARD_LOCATIONS_IMPORT_FAILED = '.Impossibile caricare le posizioni dei cantieri, è stato fornito un nome di colonna errato';
lang.GLOBAL_SERVERERROR_SUPPLIERS_IMPORT_FAILED = '.Impossibile caricare i fornitori, sono stati forniti nomi di colonna errati';
lang.GLOBAL_SERVERERROR_ROAD_HAULIERS_IMPORT_FAILED = '.Impossibile caricare gli autotrasportatori, sono stati forniti nomi di colonna errati';
lang.GLOBAL_SERVERERROR_STOCK_ITEMS_IMPORT_FAILED = '.Impossibile caricare gli articoli in stock, sono stati forniti nomi di colonna errati';
lang.GLOBAL_SERVERERROR_UNITS_FIELD_IS_MANDATORY  = 'Fornire unità di peso';
lang.GLOBAL_SERVERERROR_LEGAL_ENTITY_FIELD_IS_MANDATORY = '.Fornire persona giuridica';
lang.GLOBAL_SERVERERROR_LEGAL_ENTITY_NOT_FOUND = '.Persona giuridica fornita non trovata';
lang.GLOBAL_SERVERERROR_EXTERNAL_API_NAME_NOT_FOUND = '.Nome API esterno fornito non trovato';
lang.GLOBAL_COPY_TITLE = (itemname) => `.Fare clic per copiare ${itemname} negli Appunti`;
lang.GLOBAL_COPY_SUCCESS_MESSAGE = (name) => `.${name} è stato copiato negli appunti`;
lang.HEADER_QRCODE_HEADER = 'Scansiona il codice QR';
lang.HEADER_QRCODE_ERROR_MSG = 'Qualcosa è andato storto durante l\'apertura del lettore di codici QR';
lang.HEADER_LABEL_LOGOUT = 'Disconnettersi';
lang.HEADER_LABEL_CHANGE_PASSWORD = 'Cambia la password';
lang.HEADER_LABEL_SWITCH_ROLE = '.Cambia ruolo';
lang.HEADER_LABEL_SWITCH_TENANT = '.Cambia inquilino';
lang.HEADER_MODAL_LOGOUT = 'Vuoi uscire? verranno perse tutte le modifiche non salvate.';
lang.HEADER_MODAL_SWITCH_ROLE = '.Vuoi cambiare ruolo? Tutte le modifiche non salvate andranno perse.';
lang.HEADER_MODAL_SWITCH_TENANT = '.Vuoi cambiare inquilino? Tutte le modifiche non salvate andranno perse.';
lang.ADMIN_SECTIONENTITY_USER = 'Utente';
lang.ADMIN_SECTIONENTITY_STOCK_ITEM = 'Articolo';
lang.ADMIN_SECTIONENTITY_SUPPLIER = 'Fornitore';
lang.ADMIN_SECTIONENTITY_ROAD_HAULIER = '.Vettore';
lang.ADMIN_SECTIONENTITY_YARD_LOCATION = '.Yard Località';
lang.ADMIN_SECTIONENTITY_PRINTER = '.Stampante';
lang.ADMIN_SECTIONENTITY_SCALE = '.Scala';
lang.ADMIN_SECTIONENTITY_MATERIAL = 'Materiale';
lang.ADMIN_SECTIONENTITY_MACHINE = 'Macchina';
lang.ADMIN_SECTIONENTITY_TARGET_BATCH = 'Batch target';
lang.ADMIN_SECTIONENTITY_PACKAGE = 'Pacchetto';
lang.ADMIN_SECTIONENTITY_EWC = 'Codice CER';
lang.ADMIN_SECTIONENTITY_ORIGIN = 'Origine';
lang.ADMIN_ACTIVITYLOG_TITLE = 'Registro delle attività';
lang.ADMIN_ACTIVITYLOG_ACTION = 'Azione';
lang.ADMIN_ACTIVITYLOG_CREATEDAT = 'creato in';
lang.ADMIN_ACTIVITYLOG_USER = 'Utente';
lang.ADMIN_ACTIVITYLOG_MESSAGE = 'Messaggio';
lang.ADMIN_MATERIALS_CREATE_SUCCESS_MSG = 'Nuovo materiale è stato creato';
lang.ADMIN_MATERIALS_TITLE = 'Materiale';
lang.ADMIN_MATERIALS_CREATE_NEW = 'Crea nuovo materiale';
lang.ADMIN_MATERIALDETAIL_UPDATE_MSG = 'Materiali è stato aggiornato';
lang.ADMIN_MATERIALDETAIL_DELETE_MSG = 'Materiali è stato eliminato';
lang.ADMIN_MATERIALDETAIL_HEADER = 'dettaglio materiale';
lang.ADMIN_MATERIALDETAIL_CONFIRMATION_HEADER = 'Elimina conferma materiale';
lang.ADMIN_MATERIALDETAIL_CONFIRMATION_TEXT = (name) => `Vuoi eliminare il materiale <strong> ${name} </strong>?`;
lang.ADMIN_PRINTERLIST_CREATE_SUCCESS_MSG = 'Nuova stampante è stato creato';
lang.ADMIN_PRINTERLIST_HEADER = 'stampanti';
lang.ADMIN_PRINTERLIST_ADD_BTN = 'Crea stampante';
lang.ADMIN_PRINTERLIST_NAME = 'Nome stampante';
lang.ADMIN_PRINTERLIST_CREATE_NEW = 'Crea nuova stampante';
lang.ADMIN_PRINTERDETAIL_UPDATE_MSG = 'Stampante è stato aggiornato';
lang.ADMIN_PRINTERDETAIL_DELETE_MSG = 'Stampante stato eliminato';
lang.ADMIN_PRINTERDETAIL_HEADER = 'Posizione stampante dettagli';
lang.ADMIN_PRINTERDETAIL_CONFIRMATION_HEADER = 'Eliminare la conferma della stampante';
lang.ADMIN_PRINTERDETAIL_CONFIRMATION_TEXT = (name) => `Vuoi eliminare la stampante <strong> ${name} </strong>?`;
lang.ADMIN_ROADHAULIERLIST_HEADER = 'Trasp';
lang.ADMIN_ROADHAULIERLIST_UPLOAD_BTN = 'Carica autotrasportatori';
lang.ADMIN_ROADHAULIERLIST_AUTHORIZATION_NUMBER = '.Numero di autorizzazione';
lang.ADMIN_ROADHAULIERLIST_EXPIRATION_DATE = '.Data di scadenza';
lang.ADMIN_COMMONLIST_CREATE_HEADER = (name) => `.Creare nuova ${name}`;
lang.ADMIN_COMMONLIST_UPLOAD_HEADER = (name) => `.Carica ${name}`;
lang.ADMIN_COMMONLIST_CREATE_SUCCESS_MSG = (name) => `.Nuova ${name} fu creata`;
lang.ADMIN_COMMONDETAIL_UPDATE_MSG = (name) => `.${name} è stato aggiornato`;
lang.ADMIN_COMMONDETAIL_HIDE_MSG = (name) => `.${name} hided`;
lang.ADMIN_COMMONDETAIL_UNHIDE_MSG = (name) => `.${name} stato unhidden`;
lang.ADMIN_COMMONDETAIL_HEADER = (name) => `.Dettaglio del ${name}`;
lang.ADMIN_COMMONDETAIL_HIDE_CONFIRMATION_HEADER = (name) => `.Conferma il ${name} Nascondi`;
lang.ADMIN_COMMONDETAIL_HIDE_CONFIRMATION_TEXT = (name) => `.Vuoi nascondere <strong> ${name} </strong>?`;
lang.ADMIN_COMMONDETAIL_UNHIDE_CONFIRMATION_HEADER = (name) => `.Conferma Unhide ${name}`;
lang.ADMIN_COMMONDETAIL_UNHIDE_CONFIRMATION_TEXT = (name) => `.Vuoi far riapparire il <strong> ${name} </strong>?`;
lang.ADMIN_COMMONDETAIL_ERROR_MSG = (name) => `.${name} non ha trovato`;
lang.ADMIN_ADMINLISTUPLOAD_UPLOAD_SUCCESS_MSG = 'Lista caricato con successo!';
lang.ADMIN_ADMINLISTUPLOAD_ERROR_MSG = 'Qualcosa è andato storto durante il caricamento lista.';
lang.ADMIN_ADMINLISTUPLOAD_EXAMPLE = 'Date un\'occhiata in esempio file excel:';
lang.ADMIN_SCALELIST_CREATE_SUCCESS_MSG = 'Nuova scala fu creata';
lang.ADMIN_SCALELIST_HEADER = 'Bilancia';
lang.ADMIN_SCALELIST_ADD_BTN = 'creare scala';
lang.ADMIN_SCALELIST_NAME = 'nome scala';
lang.ADMIN_SCALELIST_CREATE_NEW = 'Crea nuova scala';
lang.ADMIN_SCALEDETAIL_UPDATE_MSG = 'Scala è stato aggiornato';
lang.ADMIN_SCALEDETAIL_DELETE_MSG = 'Scala è stata eliminata';
lang.ADMIN_SCALEDETAIL_HEADER = 'particolare scala';
lang.ADMIN_SCALEDETAIL_CONFIRMATION_HEADER = 'Elimina conferma scala';
lang.ADMIN_SCALEDETAIL_CONFIRMATION_TEXT = (name) => `Vuoi eliminare scala <strong> ${name} </strong>?`;
lang.ADMIN_SCALEDETAIL_FIELD_UPDATE_ERROR = 'Nome del campo di ingresso non è definito';
lang.ADMIN_STOCKEITEMLIST_HEADER = 'Articoli di magazzino';
lang.ADMIN_STOCKEITEMLIST_UPLOAD_BTN = 'Carica magazzino Articoli';
lang.ADMIN_SUPPLIERLIST_HEADER = 'Fornitori';
lang.ADMIN_SUPPLIERLIST_UPLOAD_BTN = 'Carica fornitori';
lang.ADMIN_SUPPLIERDETAIL_UPDATE_MSG = 'Fornitore è stato aggiornato';
lang.ADMIN_SUPPLIERDETAIL_HIDE_MSG = 'Fornitore hided';
lang.ADMIN_SUPPLIERDETAIL_UNHIDE_MSG = 'Fornitore stato unhidden';
lang.ADMIN_SUPPLIERDETAIL_HEADER = 'dettaglio del fornitore';
lang.ADMIN_SUPPLIERDETAIL_HIDE_CONFIRMATION_HEADER = 'conferma il fornitore Nascondi';
lang.ADMIN_SUPPLIERDETAIL_HIDE_CONFIRMATION_TEXT = (name) => `Vuoi nascondere fornitore <strong> ${name} </strong>?`;
lang.ADMIN_SUPPLIERDETAIL_UNHIDE_CONFIRMATION_HEADER = 'conferma Unhide fornitore';
lang.ADMIN_SUPPLIERDETAIL_UNHIDE_CONFIRMATION_TEXT = (name) => `Vuoi far riapparire il fornitore <strong> ${name} </strong>?`;
lang.ADMIN_SUPPLIERDETAIL_ERROR_MSG = 'Fornitore non ha trovato';
lang.ADMIN_MACHINEDETAIL_UPDATE_MSG = 'Macchina è stato aggiornato';
lang.ADMIN_MACHINEDETAIL_HIDE_MSG = 'Macchina è stata hided';
lang.ADMIN_MACHINEDETAIL_UNHIDE_MSG = 'Macchina è stata unhidden';
lang.ADMIN_MACHINEDETAIL_HEADER = 'particolare della macchina';
lang.ADMIN_MACHINEDETAIL_HIDE_CONFIRMATION_HEADER = 'conferma macchina Nascondi';
lang.ADMIN_MACHINEDETAIL_HIDE_CONFIRMATION_TEXT = (name) => `Vuoi nascondere macchina <strong> ${name} </strong>?`;
lang.ADMIN_MACHINEDETAIL_UNHIDE_CONFIRMATION_HEADER = 'conferma Unhide macchina';
lang.ADMIN_MACHINEDETAIL_UNHIDE_CONFIRMATION_TEXT = (name) => `Volete unhide macchina <strong> ${name} </strong>?`;
lang.ADMIN_MACHINEDETAIL_ERROR_MSG = 'La macchina non ha trovato';
lang.ADMIN_PACKAGING_DETAIL_UPDATE_MSG = 'Il packaging è stato aggiornato';
lang.ADMIN_PACKAGING_DETAIL_HIDE_MSG = 'Il packaging è stato nascosto';
lang.ADMIN_PACKAGING_DETAIL_UNHIDE_MSG = 'Il packaging è stato scoperto';
lang.ADMIN_PACKAGING_DETAIL_HEADER = 'Dettaglio dell\'imballaggio';
lang.ADMIN_PACKAGING_DETAIL_HIDE_CONFIRMATION_HEADER = 'Nascondi la conferma dell\'imballaggio';
lang.ADMIN_PACKAGING_DETAIL_HIDE_CONFIRMATION_TEXT = (name) => `Vuoi nascondere la confezione <strong> ${name} </strong>?`;
lang.ADMIN_PACKAGING_DETAIL_UNHIDE_CONFIRMATION_HEADER = 'Scopri la conferma dell\'imballaggio';
lang.ADMIN_PACKAGING_DETAIL_UNHIDE_CONFIRMATION_TEXT = (name) => `Vuoi scoprire gli imballaggi <strong> ${name} </strong>?`;
lang.ADMIN_PACKAGING_DETAIL_ERROR_MSG = 'Il packaging non è stato trovato';
lang.ADMIN_TARGETBATCHDETAIL_UPDATE_MSG = 'Obiettivo lotto è stato aggiornato';
lang.ADMIN_TARGETBATCHDETAIL_HIDE_MSG = 'Obiettivo lotto è stato hided';
lang.ADMIN_TARGETBATCHDETAIL_UNHIDE_MSG = 'Obiettivo lotto è stato unhidden';
lang.ADMIN_TARGETBATCHDETAIL_HEADER = 'Bersaglio dettaglio lotti';
lang.ADMIN_TARGETBATCHDETAIL_HIDE_CONFIRMATION_HEADER = 'conferma Nascondi bersaglio lotto';
lang.ADMIN_TARGETBATCHDETAIL_HIDE_CONFIRMATION_TEXT = (name) => `Vuoi nascondere bersaglio lotto <strong> ${name} </strong>?`;
lang.ADMIN_TARGETBATCHDETAIL_UNHIDE_CONFIRMATION_HEADER = 'conferma lotto bersaglio Unhide';
lang.ADMIN_TARGETBATCHDETAIL_UNHIDE_CONFIRMATION_TEXT = (name) => `Volete unhide bersaglio lotto <strong> ${name} </strong>?`;
lang.ADMIN_TARGETBATCHDETAIL_ERROR_MSG = 'Obiettivo lotto non ha trovato';
lang.ADMIN_USERLIST_CREATE_SUCCESS_MSG = 'Nuovo utente è stato creato';
lang.ADMIN_USERLIST_HEADER = 'Gestione utenti';
lang.ADMIN_USERLIST_ADD_BTN = 'Creare un nuovo utente';
lang.ADMIN_USERDETAIL_UPDATE_MSG = 'Utente è stato aggiornato';
lang.ADMIN_USERDETAIL_DELETE_MSG = 'Utente è stato eliminato';
lang.ADMIN_USERDETAIL_DELETE_BTN = 'Elimina utente';
lang.ADMIN_USERDETAIL_HEADER = 'dettagli utente';
lang.ADMIN_USERDETAIL_CONFIRMATION_HEADER = 'Eliminare la conferma dell\'utente';
lang.ADMIN_USERDETAIL_CONFIRMATION_TEXT = (name) => `Volete eliminare l\'utente <strong> ${name} </strong>?`;
lang.ADMIN_YARDLOCATIONLIST_CREATE_SUCCESS_MSG = 'Nuova posizione cantiere è stato creato';
lang.ADMIN_YARDLOCATIONLIST_HEADER = 'Yard Sedi';
lang.ADMIN_YARDLOCATIONLIST_ADD_BTN = 'Crea posizione yard';
lang.ADMIN_YARDLOCATIONLIST_UPLOAD_BTN = 'Carica Luogo cantiere';
lang.ADMIN_YARDLOCATIONLIST_NAME = 'Yard nome della località';
lang.ADMIN_YARDLOCATIONLIST_CREATE_NEW = 'Creare nuova posizione yard';
lang.ADMIN_YARDLOCATIONDETAIL_UPDATE_MSG = 'Yard posizione è stata aggiornata';
lang.ADMIN_YARDLOCATIONDETAIL_DELETE_MSG = 'Yard posizione è stata eliminata';
lang.ADMIN_YARDLOCATIONDETAIL_HEADER = 'Yard posizione dettagli';
lang.ADMIN_YARDLOCATIONDETAIL_CONFIRMATION_HEADER = 'Cancella Regione';
lang.ADMIN_YARDLOCATIONDETAIL_CONFIRMATION_TEXT = (name) => `Vuoi eliminare la posizione <strong> ${name} </strong>?`;
lang.ADMIN_YARDLOCATIONDETAIL_NAME = 'Yard Località';
lang.ADMIN_MACHINE_LIST_HEADER = 'macchine';
lang.ADMIN_MACHINE_LIST_UPLOAD_BTN = 'Carica Machines';
lang.ADMIN_TARGET_BATCH_LIST_HEADER = 'lotti di destinazione';
lang.ADMIN_TARGET_BATCH_LIST_UPLOAD_BTN = 'Carica Lotti target';
lang.ADMIN_PACKAGING_ADD_BTN = 'Aggiungi confezione';
lang.ADMIN_PACKAGING_UPLOAD_BTN = 'Carica confezione';
lang.ADMIN_PACKAGING_CREATE_NEW_PACKAGING = 'Crea nuovo packaging';
lang.ADMIN_PACKAGING_CREATE_SUCCESS_MSG = 'È stato creato un nuovo packaging';
lang.ADMIN_EWCLIST_HEADER = 'CERi';
lang.ADMIN_EWCLIST_ADD_BTN = '.Creare CER';
lang.ADMIN_EWCLIST_UPLOAD_BTN = '.Carica CER';
lang.ADMIN_EWCLIST_CODE = '.Codice';
lang.ADMIN_EWCLIST_CREATE_NEW = '.Crea nuovo CER';
lang.ADMIN_EWCLIST_CREATE_SUCCESS_MSG = '.È stato aggiunto un nuovo codice CER';
lang.ADMIN_EWCDETAIL_UPDATE_MSG = '.Il codice CER è stato aggiornato';
lang.ADMIN_EWCDETAIL_HEADER = '.Dettaglio CER';
lang.ADMIN_EWCDETAIL_NAME = '.Codice';
lang.ADMIN_EWCDETAIL_HIDE_MSG = '.Il CER era nascosto';
lang.ADMIN_EWCDETAIL_UNHIDE_MSG = '.Il CAE è stato scoperto';
lang.ADMIN_EWCDETAIL_HIDE_CONFIRMATION_HEADER = '.Nascondi la conferma CER';
lang.ADMIN_EWCDETAIL_HIDE_CONFIRMATION_TEXT = (name) => `.Vuoi nascondere il codice CER <strong> ${name} </strong>?`;
lang.ADMIN_EWCDETAIL_UNHIDE_CONFIRMATION_HEADER = '.Scopri la conferma del CER';
lang.ADMIN_EWCDETAIL_UNHIDE_CONFIRMATION_TEXT = (name) => `.Vuoi scoprire il codice CER <strong> ${name} </strong>?`;
lang.ADMIN_EWCDETAIL_ERROR_MSG = '.CER non trovato';
lang.ADMIN_ORIGINLIST_HEADER = '.Origini';
lang.ADMIN_ORIGINLIST_ADD_BTN = '.Crea il codice di origine';
lang.ADMIN_ORIGINLIST_UPLOAD_BTN = '.Carica origine';
lang.ADMIN_ORIGINLIST_CODE = '.Codice';
lang.ADMIN_ORIGINLIST_CREATE_NEW = '.Crea nuova origine';
lang.ADMIN_ORIGINLIST_CREATE_SUCCESS_MSG = '.È stato aggiunto un nuovo codice di origine';
lang.ADMIN_ORIGINDETAIL_UPDATE_MSG = '.Il codice di origine è stato aggiornato';
lang.ADMIN_ORIGINDETAIL_HEADER = '.Dettaglio dell\'origine';
lang.ADMIN_ORIGINDETAIL_NAME = '.Codice';
lang.ADMIN_ORIGINDETAIL_HIDE_MSG = '.L\'origine era nascosta';
lang.ADMIN_ORIGINDETAIL_UNHIDE_MSG = '.L\'origine è stata scoperta';
lang.ADMIN_ORIGINDETAIL_HIDE_CONFIRMATION_HEADER = '.Nascondi la conferma dell\'origine';
lang.ADMIN_ORIGINDETAIL_HIDE_CONFIRMATION_TEXT = (name) => `.Vuoi nascondere il codice di origine <strong> ${name} </strong>?`;
lang.ADMIN_ORIGINDETAIL_UNHIDE_CONFIRMATION_HEADER = '.Scopri la conferma dell\'origine';
lang.ADMIN_ORIGINDETAIL_UNHIDE_CONFIRMATION_TEXT = (name) => `.Vuoi scoprire il codice di origine <strong> ${name} </strong>?`;
lang.ADMIN_ORIGINDETAIL_ERROR_MSG = '.Origine non trovata';
lang.ADMIN_CONTRACTS_TITLE = '.Contratti';
lang.ADMIN_CONTRACTS_FILE_IMPORT = '.Importazione di file';
lang.ADMIN_CONTRACTS_TRIGGER_SFTP_SYNC = '.Attiva sincronizzazione SFTP';
lang.ADMIN_CONTRACTS_FILE_IMPORTED_MSG = '.File importato';
lang.ADMIN_CONTRACTS_SFTP_TRIGGERED_MSG = '.Importazione SFTP attivata';
lang.ADMIN_PROCESSING_UNIT_MINUTES = '£/ora';
lang.ADMIN_PROCESSING_UNIT_TONS = '£/tonnellata';
lang.ADMIN_FORM_QUALITY_REFRESH_CONFIRMATION_TITLE = 'Aggiorna la conferma delle varianti di prodotto';
lang.ADMIN_FORM_QUALITY_REFRESH_PRODUCT_VARIANTS_TEXT = 'Il processo di aggiornamento può richiedere alcuni minuti. Vuoi procedere?';
lang.ADMIN_FORM_QUALITY_REFRESH_PRODUCT_VARIANTS_SUCCESS_MESSAGE = 'Varianti di prodotto aggiornate correttamente';
lang.ADMIN_FORM_QUALITY_REFRESH_PRODUCT_VARIANTS_IN_PROGRESS_MESSAGE = 'Aggiorna varianti di prodotto ancora in corso. Aggiorna la pagina dopo 2 minuti';
lang.ADMIN_REFRESH_CONFIRMATION_TITLE = (name) => `Aggiorna la conferma di ${name}`;
lang.ADMIN_REFRESH_SUCCESS_MESSAGE = (name) => `${name} aggiornato correttamente`;
lang.ADMIN_REFRESH_CONFIRMATION_TEXT = 'Il processo di aggiornamento può richiedere alcuni minuti. Vuoi procedere?';
lang.ADMIN_REFRESH_IN_PROGRESS_MESSAGE = (name) => `Aggiornamento di ${name} ancora in corso. Aggiorna la pagina dopo 2 min`;
lang.ADMIN_EMPLOYEE_REFRESH_EMPLOYEES_SUCCESS_MESSAGE = 'Dipendenti aggiornati correttamente';
lang.ADMIN_LASERNET_REPORT_NAME = 'Nome rapporto Laserner';
lang.ADMIN_LASERNET_DEFAULT_NUMBER_OF_COPIES = 'Numero di copie predefinito';
lang.ADMIN_LASERNET_DOCUMENT_TYPE = 'tipo di documento';
lang.ADMIN_LASERNET_DOCUMENT_TYPE_DETAIL = 'Dettaglio del tipo di documento';
lang.ADMIN_LASERNET_DOCUMENT_TYPE_ID = 'ID tipo di documento';
lang.ADMIN_LASERNET_PROVIDE_REPORT_NAME_MSG = 'Per favore, fornisci il nome del rapporto Lasernet';
lang.ADMIN_LASERNET_CONFIGURATIONS_HEADER = 'Configurazioni della stampante';
lang.ADMIN_LASERNET_CONFIGURATION_DETAILS = 'Dettagli di configurazione';
lang.ADMIN_LASERNET_CONFIGURATION_DELETE_CONFIRMATION_HEADER = 'Elimina la conferma';
lang.ADMIN_LASERNET_CONFIGURATION_DELETE_CONFIRMATION_TEXT = 'Vorresti eliminare la configurazione?';
lang.ADMIN_LASERNET_CONFIGURATION_DELETE_SUCCESS_MESSAGE = 'La conferma è stata eliminata con successo';
lang.ADMIN_LASERNET_PRINTER_ID = 'ID stampante';
lang.SUPERADMIN_TENANTLIST_CREATE_SUCCESS_MSG = 'Nuovo inquilino è stato creato';
lang.SUPERADMIN_TENANTLIST_HEADER = 'Gli inquilini';
lang.SUPERADMIN_TENANTLIST_ADD_BTN = 'Aggiungere nuova';
lang.SUPERADMIN_TENANTLIST_GRN = 'BE prefisso';
lang.SUPERADMIN_TENANTLIST_CREATE_NEW = 'Crea nuovo inquilino';
lang.SUPERADMIN_TENANTDETAIL_UPDATE_MSG = 'Inquilino è stato aggiornato';
lang.SUPERADMIN_TENANTDETAIL_DELETE_MSG = 'Inquilino stato eliminato';
lang.SUPERADMIN_TENANTDETAIL_HEADER = 'dettaglio inquilino';
lang.SUPERADMIN_TENANTDETAIL_CONFIRMATION_HEADER = 'Elimina conferma inquilino';
lang.SUPERADMIN_TENANTDETAIL_CONFIRMATION_TEXT = (name) => `Vuoi eliminare inquilino <strong> ${name} </strong>?`;
lang.SUPERADMIN_TENANTDETAIL_SITE = 'Luogo';
lang.SUPERADMIN_TENANTDETAIL_CHECK_FOR_MISSING_ATTACHMENTS = 'Verifica la presenza di allegati mancanti';
lang.SUPERADMIN_TENANTDETAIL_ATTACHMENTS_WARNING_AG_LEVEL = 'Su Consigliato Buon livello';
lang.SUPERADMIN_TENANTDETAIL_ATTACHMENTS_WARNING_DELIVERY_LEVEL = 'A livello di consegna';
lang.SUPERADMIN_TENANTDETAIL_REQUIRE_CLASSIFICATION = 'Richiedi classificazione';
lang.SUPERADMIN_TENANTDETAIL_REQUIRE_CONTAMINATION = 'Richiede contaminazione';
lang.SUPERADMIN_TENANTDETAIL_CONFIRM_OVERWRITING_DESCRIPTION_IN_SORTING_REPORT = 'Conferma la sovrascrittura della descrizione nel rapporto di ordinamento';
lang.SUPERADMIN_TENANTDETAIL_DISPLAY_TARE_WEIGHT_FOR_RECEIVED_GOOD = 'Visualizza il peso della tara per il bene ricevuto bene';
lang.SUPERADMIN_TENANTDETAIL_IS_STOCK_CODE_REQUIRED_FOR_VERIFY = 'È il codice stock richiesto per verificare';
lang.SUPERADMIN_TENANTADMIN_UPDATE_MSG = 'inquilino aggiornato';
lang.SUPERADMIN_TENANTADMIN_ADD_MSG = 'Admin aggiunto';
lang.SUPERADMIN_TENANTADMIN_DELETE_MSG = 'Admin cancellato';
lang.SUPERADMIN_TENANTADMIN_TENANT = 'Inquilino';
lang.SUPERADMIN_TENANTADMIN_LOCATION_CODE = 'Codice Località';
lang.SUPERADMIN_TENANTADMIN_REGION_CODE = 'Codice regione';
lang.SUPERADMIN_TENANTADMIN_GRN_VALUE = 'Valore attuale GRN';
lang.SUPERADMIN_TENANTADMIN_LAB_SEQUENCE_VALUE = 'Valore attuale della sequenza di laboratorio';
lang.SUPERADMIN_TENANTADMIN_WORKFLOW = 'Flusso di lavoro';
lang.SUPERADMIN_TENANTADMIN_CHOOSE_WORKFLOW = 'Scegli il flusso di lavoro';
lang.SUPERADMIN_TENANTADMIN_TIMEZONE = 'Fuso orario';
lang.SUPERADMIN_TENANTADMIN_INTEGRATION_API_BASE = 'Base API di integrazione';
lang.SUPERADMIN_TENANTADMIN_ADMINS = 'amministratori';
lang.SUPERADMIN_TENANTADMIN_ADD_ADMIN_BTN = 'Aggiungere Admin';
lang.SUPERADMIN_TENANTADMIN_NO_ADMINS = 'Nessun amministratori';
lang.SUPERADMIN_TENANTADMIN_HEADER = 'Aggiungi utente admin';
lang.SUPERADMIN_TENANTADMINLINE_HEADER = 'utente Admin';
lang.SUPERADMIN_TENANTADMINLINE_CONFIRMATION_HEADER_HIDE = '.Nascondi admin';
lang.SUPERADMIN_TENANTADMINLINE_CONFIRMATION_TEXT_HIDE = (name) => `.Vuoi nascondere <strong> ${name} </strong>?`;
lang.SUPERADMIN_TENANTADMINLINE_CONFIRMATION_HEADER_UNHIDE = '.Scopri admin';
lang.SUPERADMIN_TENANTADMINLINE_CONFIRMATION_TEXT_UNHIDE = (name) => `.Ti piacerebbe scoprire <strong>${name}</strong>?`;
lang.SUPERADMIN_SHAREPOINT_CHOOSE_SITE = '.Scegli il sito Sharepoint';
lang.SUPERADMIN_SHAREPOINT_IMAGE_FOLDER_PATH = '.Percorso cartella immagine';
lang.SUPERADMIN_SHAREPOINT_SITES = '.Siti sharepoint';
lang.SUPERADMIN_APP_USERS_TITLE = '.Registrazioni app';
lang.SUPERADMIN_APP_USERS_ADD_MSG = '.Registrazione app aggiunta';
lang.SUPERADMIN_APP_USERS_ADD_TITLE = '.Aggiungi la registrazione dell\'app';
lang.SUPERADMIN_APP_USERS_UPDATE_MSG = '.Registrazione app aggiornata';
lang.SUPERADMIN_APP_USERS_RANDOM_SECRET = 'Generare segreti casuali';
lang.SUPERADMIN_APP_USERS_COPY_TEXT = 'Copia questo valore perché non sarai in grado di recuperare la chiave dopo aver salvato e chiuso questo utente dell\'app';
lang.SUPERADMIN_CONNECTIONS_TITLE = 'Connessioni';
lang.SUPERADMIN_CONNECTIONS_UPDATE_SUCCESS_MSG = 'ID client e segreto sono stati aggiornati correttamente';
lang.SUPERADMIN_CONNECTIONS_D365_DESCRIPTION = 'D365 Master Data e Connessione API YardLink';
lang.SUPERADMIN_CONNECTIONS_SHAREPOINT_DESCRIPTION = 'Connessione API online di SharePoint';
lang.MENU_ADMIN_TITLE = 'Amministrazione';
lang.MENU_ADMIN_USER_MANAGEMENT = 'Gestione utenti';
lang.MENU_ADMIN_ACTIVITY_LOG = 'Registro delle attività';
lang.MENU_ADMIN_STOCK_ITEMS = 'articoli di magazzino';
lang.MENU_ADMIN_SUPPLIERS = 'Fornitori';
lang.MENU_ADMIN_ROAD_HAULIERS = 'Trasp';
lang.MENU_ADMIN_YARD_LOCATION = 'posizione Yard';
lang.MENU_ADMIN_PRINTERS = 'stampanti';
lang.MENU_ADMIN_LASERNET_PRINTERS = 'Stampanti lasert';
lang.MENU_ADMIN_DOCUMENT_TYPES = 'Tipi di documenti';
lang.MENU_ADMIN_CONFIGURATIONS = 'Configurazioni';
lang.MENU_ADMIN_SCALES = 'Bilancia';
lang.MENU_ADMIN_MATERIALS = 'materiale';
lang.MENU_ADMIN_APPLICATION = 'Applicazione';
lang.MENU_ADMIN_DELIVERY_LIST = 'sulla consegna';
lang.MENU_ADMIN_MACHINES = 'macchine';
lang.MENU_ADMIN_TARGET_BATCHES = 'lotti di destinazione';
lang.MENU_ADMIN_PACKAGING = 'Confezione';
lang.MENU_ADMIN_ORIGINS = 'Origini';
lang.MENU_ADMIN_EWCS = 'CERi';
lang.MENU_SUPERADMIN_TENANTS = 'Gli inquilini';
lang.LABORATORY_ADVISEGOODS_UPDATE_MSG = 'analisi di laboratorio - aggiornato';
lang.LABORATORY_ADVISEGOODS_UPDATE_STATUS_MSG = 'Stato analisi di laboratorio - aggiornato';
lang.LABORATORY_ADVISEGOODS_DELIVERIES = 'Le consegne';
lang.LABORATORY_ADVISEGOODS_ADVISED_DESCRIPTION = 'Qualità Dichiarata';
lang.LABORATORY_ADVISEGOODS_TENANT = 'Inquilino';
lang.LABORATORY_ADVISEGOODS_SORTING_REPORT = 'Ordinamento rapporto';
lang.LABORATORY_ADVISEGOODS_LAB_STATUSES = 'stati Lab';
lang.LABORATORY_ADVISEGOODS_LAB_LIST = 'Lista Lab';
lang.LABORATORY_ANALISYS_HEADER = 'analisi di laboratorio';
lang.LABORATORY_ANALISYS_MEASUREMENTS = 'misure';
lang.LABORATORY_ANALISYS_UPDATE_STATUS_MSG = 'Stato analisi di laboratorio - aggiornato';
lang.LABORATORY_ANALISYS_WET_WEIGHT = 'peso umido';
lang.LABORATORY_ANALISYS_DRY_WEIGHT = 'Peso a secco';
lang.LABORATORY_ANALISYS_INGOTE_WEIGHT = 'peso lingotto';
lang.LABORATORY_ANALISYS_YIELD = 'dare la precedenza';
lang.LABORATORY_ANALISYS_SPECTRO_ANALYSIS = '.Spektroanaliz';
lang.LABORATORY_PRINTER_SUCCESS_MSG = 'Pagina è stato inviato a stampante';
lang.LABORATORY_PRINTER_WARN_MSG = 'Stampante non supportata usato';
lang.LABORATORY_PRINTER_ERROR_MSG = 'È verificato un errore durante la stampa';
lang.LABORATORY_PRINTER_LABEL = 'Etichetta';
lang.LABORATORY_PRINTER_NO_PRINTERS = 'Nessuna stampante';
lang.LABORATORY_SCALE_CONNECTION_ERROR_MSG = 'Il collegamento alla scala non è possibile stabilire';
lang.LABORATORY_SCALE_SCALE_ERROR_MSG = 'Scala - si è verificato un errore';
lang.LABORATORY_SCALE_FAKE_RESULT = 'risultato viso';
lang.DELIVERIES_LABORATORY_FILTER_BY = 'Filtrare per:';
lang.DELIVERIES_DELIVERIESTABLE_NEW_DELIVERY_SUCCESS_MSG = 'Nuova consegna è stata ricevuta';
lang.DELIVERIES_DELIVERIESTABLE_DELIVERY_UPDATE_MSG = (name) => `Consegna ${name} è stato aggiornato`;
lang.DELIVERIES_DELIVERIESTABLE_ADVISED_GOOD_MSG = 'Il bene consigliato è stato rivendicato';
lang.DELIVERIES_DELIVERIESTABLE_DELIVERY_RESYNCED = 'consegna risincronizzate';
lang.DELIVERIES_FILTER_SORT_BY = (name) => `Ordina per ${name}`;
lang.DELIVERIES_FILTER_GRN_SEARCH = 'BE ricerca';
lang.DELIVERIES_FILTER_ADVISED_GOOD_MSG = 'Il bene consigliato è stato rivendicato';
lang.DELIVERIES_FILTER_DELIVERY_RESYNCED = 'consegna risincronizzate';
lang.DELIVERIES_DELIVERYLIST_SIGNED_OFF_MSG = 'Consegna firmato-off';
lang.DELIVERIES_DELIVERYLIST_COMPLETED_MSG = 'consegna completato';
lang.DELIVERIES_DELIVERYLIST_SIGN_OFF = 'Firma';
lang.DELIVERIES_DELIVERYLIST_RESYNC_WB = 'risincronizzazione Wb';
lang.DELIVERIES_DELIVERYLIST_RESYNC_SR = 'risincronizzazione Sr';
lang.DELIVERIES_DELIVERYLIST_RESP = 'Resp.';
lang.DELIVERIES_DELIVERYLIST_SIGN_OFF_CONFIRMATION_HEADER = 'Sign off consegna?';
lang.DELIVERIES_DELIVERYLIST_SIGN_OFF_CONFIRMATION_TEXT = (grn) => `Ti piacerebbe firmare <strong> ${grn} </ strong>?`;
lang.DELIVERIES_DELIVERYLIST_COMPLETE_CONFIRMATION_HEADER = 'Segna come completo?';
lang.DELIVERIES_DELIVERYLIST_COMPLETE_CONFIRMATION_TEXT = (grn) => `Ti piacerebbe completare <strong> ${grn} </ strong>?`;
lang.DELIVERIES_DELIVERYLIST_EXPORT_CONFIRMATION_HEADER = 'Esportazione di consegna?';
lang.DELIVERIES_DELIVERYLIST_EXPORT_CONFIRMATION_TEXT = (grn) => `Vorresti esportare <strong> ${grn} </ strong>?`;
lang.DELIVERIES_DELIVERYLIST_WEIGHT_DISCREPANCY_CONFIRMATION_HEADER = '.Conferma della discrepanza di peso';
lang.DELIVERIES_DELIVERYLIST_WEIGHT_DISCREPANCY_CONFIRMATION_TEXT = '.Ordinamento Il peso totale non corrisponde al peso ricevuto. Vorresti firmare?';
lang.DELIVERIES_NEWDELIVERY_CREATE_ERROR_MSG = 'È verificato un errore, reindirizzando torna alla lista consegna';
lang.DELIVERIES_NEWDELIVERY_CREATE_SUCCESS_MSG = 'Consegna è stato creato';
lang.DELIVERIES_NEWDELIVERY_ATTACHMENT_ERROR_MSG = 'Carica allegato fallito, si prega di caricare di nuovo attacco';
lang.DELIVERIES_NEWDELIVERYDETAILS_CONTRACT_NUMBER = 'Numero di contratto';
lang.DELIVERIES_NEWDELIVERYDETAILS_GRN = 'BE';
lang.DELIVERIES_NEWDELIVERYDETAILS_CREATE_NEW = 'Crea nuova consegna';
lang.DELIVERIES_NEWDELIVERYDETAILS_DELIVERY_NUMBER = 'DDT';
lang.DELIVERIES_NEWDELIVERYDETAILS_REGISTRATION_NUMBER = 'Targhe';
lang.DELIVERIES_NEWDELIVERYDETAILS_CONTAINER_NUMBER = 'Numero di container';
lang.DELIVERIES_NEWDELIVERYDETAILS_ROAD_HAULIER = 'Trasp';
lang.DELIVERIES_NEWDELIVERYDETAILS_NO_ATTACHMENTS = 'Nessun allegato';
lang.DELIVERIES_NEWDELIVERYDETAILS_RESPONSIBLE_PEOPLE = 'Responsabili';
lang.DELIVERIES_NEWDELIVERYDETAILS_CREATED_BY = 'Creato da';
lang.DELIVERIES_NEWDELIVERYDETAILS_SIGNED_OFF_BY = 'Approvato da';
lang.DELIVERIES_NEWDELIVERYDETAILS_SYNCED_BY = 'Sincronizzato da';
lang.DELIVERIES_NEWDELIVERYDETAILS_COMPLETED_BY = 'Completato da';
lang.DELIVERIES_NEWDELIVERYDETAILS_STOCK_ITEM = 'Articolo';
lang.DELIVERIES_NEWDELIVERYDETAILS_ADV_DESCRIPTION = 'Qualità dichiarata';
lang.DELIVERIES_NEWDELIVERYDETAILS_ADV_WEIGHT = 'Peso dichiarata';
lang.DELIVERIES_NEWDELIVERYDETAILS_ADV_GROSS_WEIGHT = '.Adv. Peso (lordo)';
lang.DELIVERIES_NEWDELIVERYDETAILS_ADV_NET_WEIGHT = '.Adv. Peso (netto)';
lang.DELIVERIES_NEWDELIVERYDETAILS_YARD_LOCATION = 'Yard Località';
lang.DELIVERIES_NEWDELIVERYDETAILS_GROSS_WEIGHT = '.Peso lordo';
lang.DELIVERIES_NEWDELIVERYDETAILS_TARE_WEIGHT = '.Peso tara';
lang.DELIVERIES_NEWDELIVERYDETAILS_WB_TICKET = 'Bolla di pesatura No';
lang.DELIVERIES_NEWDELIVERYDETAILS_NET = 'Netto';
lang.DELIVERIES_NEWDELIVERYDETAILS_SAVE_CHANGES_CONFIRMATION_HEADER = 'Salvare le modifiche?';
lang.DELIVERIES_NEWDELIVERYDETAILS_SAVE_CHANGES_CONFIRMATION_TEXT = 'VUOI <strong> Salva modifiche </strong>?';
lang.DELIVERIES_NEWDELIVERYDETAILS_COMPLETE_CONFIRMATION_HEADER = 'Segna come completo?';
lang.DELIVERIES_NEWDELIVERYDETAILS_COMPLETE_CONFIRMATION_TEXT = 'Volete contrassegnare la consegna come <strong> completa </​​strong>?';
lang.DELIVERIES_NEWDELIVERYDETAILS_WITHOUT_ATTACHMENTS_CONFIRMATION_HEADER = 'Salva senza allegati?';
lang.DELIVERIES_NEWDELIVERYDETAILS_WITHOUT_ATTACHMENTS_CONFIRMATION_TEXT = 'Vuoi salvare la consegna <strong> senza allegati </strong>?';
lang.DELIVERIES_NEWDELIVERYDETAILS_CERTIFICATE_CONFIRMATION_HEADER = 'Salva con certificato scaduto?';
lang.DELIVERIES_NEWDELIVERYDETAILS_CERTIFICATE_CONFIRMATION_TEXT = 'Il certificato del fornitore è scaduto o è mancato. Vuoi risparmiare comunque la consegna?';
lang.DELIVERIES_NEWDELIVERYDETAILS_ORIGIN = 'Origine';
lang.DELIVERIES_NEWDELIVERYDETAILS_AUTHORIZATION_NUMBER_CONFIRMATION_HEADER = 'Salva con autorizzazione è scaduta?';
lang.DELIVERIES_NEWDELIVERYDETAILS_AUTHORIZATION_NUMBER_CONFIRMATION_TEXT = 'Il autorizzazione è scaduto. Vuoi comunque salvare la consegna?';
lang.DELIVERIES_NEWDELIVERYDETAILS_TRANSACTION_TYPE = '.Tipo di transazione';
lang.DELIVERIES_NEWDELIVERYDETAILS_TRANSACTION_TYPE_TRADE_PAYABLE = '.Debiti commerciali';
lang.DELIVERIES_NEWDELIVERYDETAILS_TRANSACTION_TYPE_TRANSFER = '.Trasferimento';
lang.DELIVERIES_NEWDELIVERYDETAILS_TRANSACTION_TYPE_RETURN_OF_REJECTS = '.Restituzione di scarti';
lang.DELIVERIES_NEWDELIVERYDETAILS_TRANSACTION_TYPE_VOID = '.Void';
lang.DELIVERIES_DELIVERYDETAILSLINE_HEADER = 'Dettagli della merce consigliata';
lang.DELIVERIES_DELIVERYDETAILSLINE_ADV_DESCRIPTION = 'Qualità dichiarata';
lang.DELIVERIES_DELIVERYDETAILSLINE_ADV_WEIGHT = 'Peso dichiarata';
lang.DELIVERIES_DELIVERYDETAILSLINE_ORDER_LINE_SEQUENCE = '.Riga ordine Seq.';
lang.DELIVERIES_DELIVERYDETAILSLINE_ADVISED_GOOD_EXTERNAL_INDEX = '.Numero di riga';
lang.DELIVERIES_DELIVERYEDIT_UPDATE_SUCCES_MSG = 'Consegna è stato aggiornato';
lang.DELIVERIES_DELIVERYEDIT_ATTACHMENT_SUCCESS_MSG = 'Allegati aggiornati con successo!';
lang.DELIVERIES_DELIVERYEDIT_ATTACHMENT_ERROR_MSG = 'Carica allegato fallito, si prega di caricare di nuovo attacco';
lang.DELIVERIES_DELIVERYEDIT_ATTACHMENT_REMOVED_MSG = 'Allegato rimosso';
lang.DELIVERIES_DELIVERYEDIT_DELETE_ATTACHMENT_HEADER_CONFIRMATION = 'Elimina conferma allegato';
lang.DELIVERIES_DELIVERYEDIT_DELETE_ATTACHMENT_TEXT_CONFIRMATION = 'Vuoi eliminare questo allegato?';
lang.DELIVERIES_DELIVERYEDIT_AG_CLAIMED_SUCCESS_MSG = (deliveryGrn) => `Consigliato buono nella consegna <strong>${deliveryGrn}<strong /> è stato rivendicato`;
lang.DELIVERIES_DELIVERYEDIT_DO_NOT_MATCH_CONTRACT_STOCK_CODES_ERROR_MSG = (codes) => `.I codici di borsa [${codes}] non corrispondono a nessuno dei codici di borsa di dOps. Si prega di contattare l\'amministratore`;
lang.DELIVERIES_DELIVERYEDIT_DELETE = 'Eliminare la consegna?';
lang.DELIVERIES_DELIVERYEDIT_DELETE_CONFIRMATION = 'Vuoi eliminare la consegna? Non sarà possibile ripristinarlo in futuro.';
lang.DELIVERIES_DELIVERYEDIT_DELETED = (name) => `La consegna ${name} è stata eliminata`;
lang.ADVISEDGOOD_ROUTE_EWC_NUMBER = 'Codice CER';
lang.ADVISEDGOOD_ROUTE_DRIVER = 'autista';
lang.ADVISEDGOOD_ROUTE_UPDATE_SUCCESS_MSG = 'Consigliato buona aggiornato';
lang.ADVISEDGOOD_ROUTE_WEIGHTS_UPDATED = (entity) => `.Pesi aggiornati in: ${entity}`;
lang.ADVISEDGOOD_ROUTE_FLAG_ERROR_MSG = 'buona consigliata è stato contrassegnato';
lang.ADVISEDGOOD_ROUTE_FLAG_SUCCESS_MSG = 'Consigliato buona era UN-battente bandiera';
lang.ADVISEDGOOD_ROUTE_COMPLETED_MSG = 'buona consigliata è stata completata';
lang.ADVISEDGOOD_ROUTE_VERIFIED_MSG = 'buona consigliata è stata verificata';
lang.ADVISEDGOOD_ROUTE_LAB_MSG = 'analisi di laboratorio è stato creato correttamente';
lang.ADVISEDGOOD_ROUTE_SAVE_BEFORE_TRANSFER_MSG = 'buona consigliata deve essere salvato prima di creare il trasferimento';
lang.ADVISEDGOOD_ROUTE_SAVE_BEFORE_LAB_ANALYSIS_MSG = 'buona consigliata deve essere salvato prima di creare nuove analisi di laboratorio';
lang.ADVISEDGOOD_ROUTE_SAVE_BEFORE_PRINT_MSG = 'buona consigliata deve essere salvato prima della stampa';
lang.ADVISEDGOOD_ROUTE_STATUS_ROLLBACK = 'Rimandare a TIER2';
lang.ADVISEDGOOD_ROUTE_VEHICLE_REGISTRATION = 'Targhe';
lang.ADVISEDGOOD_ROUTE_GROSS_WEIGHT = 'Peso lordo';
lang.ADVISEDGOOD_ROUTE_TARE_WEIGHT = 'Peso tara';
lang.ADVISEDGOOD_ROUTE_NET_WEIGHT = 'Peso netto';
lang.ADVISEDGOOD_ROUTE_GROSS_WEIGHT_REQUIRED = '.Peso lordo richiesto';
lang.ADVISEDGOOD_ROUTE_RECEIVED_WEIGHT = 'Peso ricevuto';
lang.ADVISEDGOOD_ROUTE_TARE_WEIGHT_REQUIRED = '.Peso tara richiesto';
lang.ADVISEDGOOD_ROUTE_SORTING_REPORT = 'Rapporto di ordinamento';
lang.ADVISEDGOOD_ROUTE_BALANCED = 'Equilibrato';
lang.ADVISEDGOOD_ROUTE_ZIP_DOWNLOADED_MSG = 'File zip scaricato';
lang.ADVISEDGOOD_ROUTE_PICTURES = 'Immagini';
lang.ADVISEDGOOD_ROUTE_NO_PICTURE = 'Nessuna immagine';
lang.ADVISEDGOOD_ROUTE_UPLOADING_PICTURES = 'Caricamento foto ...';
lang.ADVISEDGOOD_ROUTE_COMMENT = 'Commento';
lang.ADVISEDGOOD_ROUTE_NO_COMMENT = 'Nessun commento';
lang.ADVISEDGOOD_ROUTE_FLAG_CONFIRMATION_HEADER = (hasFlag) => `${hasFlag} conferma`;
lang.ADVISEDGOOD_ROUTE_FLAG_CONFIRMATION_TEXT = (className, hasFlag) => `Volete <strong class = ${className}> ${hasFlag} </strong> questa consigliato bene?`;
lang.ADVISEDGOOD_ROUTE_LIVING_PAGE_CONFIRMATION_HEADER = 'Salvare le modifiche?';
lang.ADVISEDGOOD_ROUTE_LIVING_PAGE_CONFIRMATION_TEXT = 'VUOI <strong> Salva modifiche </strong>?';
lang.ADVISEDGOOD_ROUTE_COMPLETE_CONFIRMATION_HEADER = 'Segna come completo?';
lang.ADVISEDGOOD_ROUTE_COMPLETE_CONFIRMATION_TEXT = 'Ti piacerebbe celebrare questo consigliato buono come <strong> completa </strong>?';
lang.ADVISEDGOOD_ROUTE_VERIFIED_CONFIRMATION_HEADER = 'Segna come verificato';
lang.ADVISEDGOOD_ROUTE_VERIFIED_CONFIRMATION_TEXT = 'Ti piacerebbe celebrare questo consigliato buono come <strong> verificato </strong>?';
lang.ADVISEDGOOD_ROUTE_VERIFIED_WARNING_CONFIRMATION_TEXT = '.Il totale dell\'ordinamento è maggiore del peso ricevuto dalla bilancia per camion. Per verificare, si prega di confermare che il peso di smistamento è stato verificato su una bilancia a piattaforma.';
lang.ADVISEDGOOD_ROUTE_CLAIMED_CONFIRMATION_HEADER = 'Rollback alla condizione esatta?';
lang.ADVISEDGOOD_ROUTE_CLAIMED_CONFIRMATION_TEXT = 'Ti piacerebbe far ritirare lo status di <strong> rivendicato </strong>?';
lang.ADVISEDGOOD_ROUTE_UNLOADED_CONFIRMATION_HEADER = 'Tornare allo stato scaricato?';
lang.ADVISEDGOOD_ROUTE_UNLOADED_CONFIRMATION_TEXT = 'Desideri ripristinare lo stato su <strong>scaricato</strong>?';
lang.ADVISEDGOOD_ROUTE_SORTING_TOTAL_GROSS = 'Ordinamento totale (lordo)';
lang.ADVISEDGOOD_ROUTE_SORTING_TOTAL_NET = 'Totale ordinamento (netto)';
lang.ADVISEDGOOD_ROUTE_UPDATE_GROSS_WEIGHT_SUCCESS_MSG = 'Peso lordo è stato aggiornato';
lang.ADVISEDGOOD_ROUTE_UPDATE_TARE_WEIGHT_SUCCESS_MSG = 'peso Ricevuto è stato aggiornato';
lang.ADVISEDGOOD_ROUTE_UPDATE_EWC_CODE_SUCCESS_MSG = 'Codice CER è stato aggiornato';
lang.ADVISEDGOOD_ROUTE_DELIVERY_TYPE = 'Tipo di consegna';
lang.ADVISEDGOOD_ROUTE_LOCATION_CONFIRMATION_HEADER = 'Conferma posizione di scarico';
lang.ADVISEDGOOD_ROUTE_LOCATION_CONFIRMATION_TEXT = 'Selezionare la posizione Yard per la Discarica';
lang.ADVISEDGOOD_ROUTE_UNLOAD_LOCATION = 'scaricare Località';
lang.ADVISEDGOOD_ROUTE_VERIFY_WEIGHT_CHECK_ERROR = 'buona comunicato non può essere verificata perché ordinamento totale è maggiore peso ricevute';
lang.ADVISEDGOOD_ROUTE_UNCLAIM_CONFIRMATION_HEADER = 'Annulli la rivendicazione consigliato bene';
lang.ADVISEDGOOD_ROUTE_UNCLAIM_CONFIRMATION_TEXT = 'Vorresti non rivendicare il bene consigliato?';
lang.ADVISEDGOOD_ROUTE_UNCLAIMED_MSG = 'Il bene consigliato era non reclamato';
lang.ADVISEDGOOD_ROUTE_RG_AMOUNT_WARNING_MESSAGE = '.L\'importo della merce ricevuta non può essere superiore a 10';
lang.ADVISEDGOOD_ROUTE_TRUCK_RECEIVED_WEIGHT = '.Truck ha ricevuto peso';
lang.ADVISEDGOOD_ROUTE_TRUCK_SORTING_TOTAL = '.Totale totale ordinamento camion (lordo)';
lang.ADVISEDGOOD_ROUTE_PACKAGING = '.Confezione';
lang.ADVISEDGOOD_ROUTE_NO_PACKAGING = '.No packaging';
lang.ADVISEDGOOD_ROUTE_SIGN_OFF_AG = 'Firmare Ag';
lang.ADVISEDGOOD_ROUTE_SIGN_OFF_AG_CONFIRMATION_HEADER = 'Firmare Ag?';
lang.ADVISEDGOOD_ROUTE_SIGN_OFF_AG_CONFIRMATION_TEXT = 'Vorresti firmare AG?';
lang.ADVISEDGOOD_ROUTE_SIGN_OFF_AG_SUCCESS_MESSAGE = 'AG è stato firmato con successo';
lang.ADVISEDGOOD_ROUTE_WITHOUT_ATTACHMENTS_CONFIRMATION_HEADER = 'Salvare senza allegati?';
lang.ADVISEDGOOD_ROUTE_WITHOUT_ATTACHMENTS_CONFIRMATION_TEXT = 'Vuoi salvare il buono consigliato <strong>senza allegati</strong>?';
lang.ADVISEDGOOD_ROUTE_OVERWRITE_RG_DESCRIPTION_CONFIRMATION_TEXT = 'Vuoi sovrascrivere la descrizione dell\'oggetto?';
lang.ADVISEDGOOD_ROUTE_DELETE = 'Elimina consigliato bene?';
lang.ADVISEDGOOD_ROUTE_DELETE_CONFIRMATION = 'Vorresti eliminare il bene consigliato? Non sarà possibile ripristinarlo in futuro.';
lang.ADVISEDGOOD_ROUTE_DELETED = (name) => `Consigliato Buono ${name} è stato cancellato`;
lang.ADVISEDGOOD_SORTINGREPORT_HEADER = 'Ordinamento report di dettaglio';
lang.ADVISEDGOOD_SORTINGREPORT_TRANSFER_ERROR_MSG = 'Ricevuto il bene non può essere trasferito a causa del peso di trasferimento è più grande di peso attuale';
lang.ADVISEDGOOD_SORTINGREPORT_TRANSFER_SUCCESS_MSG = 'Ricevuto buon stato trasferito';
lang.ADVISEDGOOD_SORTINGREPORT_STOCK_CODE = 'Codice magazzino';
lang.ADVISEDGOOD_SORTINGREPORT_TRANSFER = 'Trasferimento';
lang.ADVISEDGOOD_SORTINGREPORT_LAB_ANALYSIS = 'analisi di laboratorio';
lang.ADVISEDGOOD_SORTINGREPORT_NO_LAB_ANALYSIS = 'No analisi di laboratorio richieste';
lang.ADVISEDGOOD_SORTINGREPORT_MATERIAL = 'Materiale';
lang.ADVISEDGOOD_SORTINGREPORT_WI_DONE = 'WI fatto';
lang.ADVISEDGOOD_SORTINGREPORT_CREATE_LAB_ANALYSIS_HEADER = 'Crea una nuova analisi di laboratorio';
lang.ADVISEDGOOD_SORTINGREPORT_CREATE_LAB_ANALYSIS_TEXT = 'Vuoi creare una nuova analisi di laboratorio?';
lang.ADVISEDGOOD_SORTINGREPORT_DELETE_LAB_ANALYSIS_HEADER = 'Eliminare Laboratorio di Analisi';
lang.ADVISEDGOOD_SORTINGREPORT_DELETE_LAB_ANALYSIS_TEXT = (labAnalysisName) => `Vuoi eliminare <strong>${labAnalysisName}</strong> richiesta?`;
lang.ADVISEDGOOD_SORTINGREPORT_MACHINE = 'Macchina';
lang.ADVISEDGOOD_SORTINGREPORT_TARGET_BATCH = 'Batch target';
lang.ADVISEDGOOD_SORTINGREPORT_BULK_DENSITY = 'Densità apparente';
lang.ADVISEDGOOD_SORTINGREPORT_FOUND_QUALITY = 'Qualità trovato';
lang.ADVISEDGOOD_SORTINGREPORT_MAIN_TYPE = 'Tipo principale';
lang.ADVISEDGOOD_SORTINGREPORT_MATERIAL_DESCRIPTION = 'descrizione del materiale';
lang.ADVISEDGOOD_SORTINGREPORT_EMPTY_REQUIRED_MSG = (emptyRequiredField) => `È necessario riempire <strong> ${emptyRequiredField} </ strong> prima di salvare`;
lang.ADVISEDGOOD_SORTINGREPORT_SAVE_BEFORE_ADD_PICTURE_MSG = 'buona consigliata deve essere salvato prima di aggiungere foto';
lang.ADVISEDGOOD_SORTINGREPORT_MACHINE_USAGE = 'Cambia posizione';
lang.ADVISEDGOOD_SORTINGREPORT_TARGET_LOCATION = 'Percorso di destinazione';
lang.ADVISEDGOOD_LABANALYSIS_LAB_REMARKS = 'Osservazioni Lab';
lang.ADVISEDGOOD_GALERY_DELETE_CONFIRMATION_HEADER = 'Elimina conferma picture';
lang.ADVISEDGOOD_GALERY_DELETE_CONFIRMATION_TEXT = 'Vuoi cancellare questa immagine?';
lang.ADVISEDGOOD_DEDUCTION_FREE_WATER = 'acqua gratis';
lang.ADVISEDGOOD_DEDUCTION_FREE_OIL = 'senza olio';
lang.ADVISEDGOOD_DEDUCTION_FREE_EMULSION = 'Emulsione gratis';
lang.ADVISEDGOOD_DEDUCTION_FREE_SNOW_ICE = 'Neve gratis / Ice';
lang.ADVISEDGOOD_DEDUCTION_NO_DEDUCTION = 'nessuna deduzione';
lang.ADVISEDGOOD_DEDUCTION_DEDUCTION = 'Deduzione';
lang.ADVISEDGOOD_DEDUCTION_MODAL_HEADER = 'Confermare INFORMATI buona Unloaded';
lang.ADVISEDGOOD_UNLOAD_TITLE = 'scarico';
lang.ADVISEDGOOD_UNLOAD_UPDATE_MSG = 'Consigliato Buona scarico completato';
lang.RECEIVED_GOOD_WEIGHT_NEGATIVE_CONFIRMATION_TITLE = '.Conferma del peso negativo';
lang.RECEIVED_GOOD_WEIGHT_NEGATIVE_CONFIRMATION_TEXT = '.Il peso del materiale è negativo. Si prega di confermare';
lang.RECEIVED_GOOD_FLAG_ERROR_MSG = 'Il buono ricevuto è stato FLAGGED';
lang.RECEIVED_GOOD_FLAG_SUCCESS_MSG = 'Il buono ricevuto era UN-FLAGGED';
lang.RECEIVED_GOOD_FLAG_CONFIRMATION_TEXT = (className, hasFlag) => `Vorresti <strong class=${className}> ${hasFlag} </strong> ricevuto correttamente?`;
lang.RECEIVED_GOOD_CONTAMINATION_TITLE = 'Contaminazione';
lang.RECEIVED_GOOD_CONTAMINATION_OTHER = 'Altro';
lang.RECEIVED_GOOD_CONTAMINATION_NO_CONTAMINATION = 'Nessuna Contaminazione';
lang.RECEIVED_GOOD_CONTAMINATION_NON_METALLIC_ATTACH = 'Non metallici Att.';
lang.RECEIVED_GOOD_CONTAMINATION_WATER = 'Acqua';
lang.RECEIVED_GOOD_CONTAMINATION_FREE_OIL = 'free Olio';
lang.RECEIVED_GOOD_CONTAMINATION_DIRT = 'Sporco';
lang.RECEIVED_GOOD_CONTAMINATION_WOOD = 'Wood';
lang.RECEIVED_GOOD_CONTAMINATION_PLASTIC = 'Plastica';
lang.RECEIVED_GOOD_CONTAMINATION_GLASS_WOOL = 'Lana di vetro';
lang.RECEIVED_GOOD_CONTAMINATION_BURNING_SLAG = 'Bruciando scoria';
lang.RECEIVED_GOOD_CONTAMINATION_BITUMEN = 'Bitume';
lang.RECEIVED_GOOD_CONTAMINATION_OIL_CREASE = 'allegare. Olio / Grasso';
lang.RECEIVED_GOOD_CONTAMINATION_CARDBOARD = 'Cartone';
lang.RECEIVED_GOOD_CONTAMINATION_METALLIC_ATTACH = 'metallico Allega';
lang.RECEIVED_GOOD_CONTAMINATION_IRON = 'Ferro';
lang.RECEIVED_GOOD_CONTAMINATION_MN_STEEL = 'Mn-Steel';
lang.RECEIVED_GOOD_CONTAMINATION_10ER_CR_STEEL = 'CrFe 10';
lang.RECEIVED_GOOD_CONTAMINATION_13ER_CR_STEEL = 'CrFe 13';
lang.RECEIVED_GOOD_CONTAMINATION_301 = 'AISI 301';
lang.RECEIVED_GOOD_CONTAMINATION_17ER_CR_STEEL = 'CrFe 17';
lang.RECEIVED_GOOD_CONTAMINATION_SHREDDER = 'Trituratore';
lang.RECEIVED_GOOD_CONTAMINATION_INCINERATOR_MAT = 'Mat Inceneritore';
lang.RECEIVED_GOOD_CONTAMINATION_DIFFERENT_METALS = 'diversi metalli';
lang.RECEIVED_GOOD_CONTAMINATION_CU = 'Cu';
lang.RECEIVED_GOOD_CONTAMINATION_AL = 'Al';
lang.RECEIVED_GOOD_CONTAMINATION_MS = 'Ms';
lang.RECEIVED_GOOD_CONTAMINATION_PB = 'Pb';
lang.RECEIVED_GOOD_CONTAMINATION_TI = 'Ti';
lang.RECEIVED_GOOD_CONTAMINATION_STST_CU_COOLER = 'INOX / Cu-raffreddamento';
lang.RECEIVED_GOOD_CONTAMINATION_MAT_FOR_SHREDDING = 'Stuoia. per triturazione';
lang.RECEIVED_GOOD_CONTAMINATION_TURNINGS_IN_SOLIDS = 'Torniture in solidi';
lang.RECEIVED_GOOD_CONTAMINATION_CR_STEEL_TUR = 'CrFe Tur.';
lang.RECEIVED_GOOD_CONTAMINATION_LOW_ALLOYED_TUR = 'bassa legata con Tur.';
lang.RECEIVED_GOOD_CONTAMINATION_STST_TURNINGS = 'INOX Turnings';
lang.RECEIVED_GOOD_CONTAMINATION_304_TURNINGS = '304 Turnings';
lang.RECEIVED_GOOD_CONTAMINATION_316_TURNINGS = '316 Turnings';
lang.RECEIVED_GOOD_CONTAMINATION_NOT_IN_FURNACE_SIZE = 'Non in Fornace Size';
lang.RECEIVED_GOOD_CONTAMINATION_FOR_MOBILE_SHEAR = 'Per Shear mobili';
lang.RECEIVED_GOOD_CONTAMINATION_FOR_STATIONARY_SHEAR = 'Per Shear stazionario';
lang.RECEIVED_GOOD_CONTAMINATION_FOR_TORCH_CUTTING = 'Per il taglio della torcia';
lang.RECEIVED_GOOD_CONTAMINATION_FOR_PRESS = 'Per Press';
lang.RECEIVED_GOOD_CONTAMINATION_NO_AGGREGATE_NECESSARY = 'Non sono aggregate necessarie';
lang.RECEIVED_GOOD_CONTAMINATION_CONSISTS_HOLLOW_BODIES = 'Consiste corpi cavi';
lang.RECEIVED_GOOD_CONTAMINATION_MATERIAL_FORMS = 'forme materiali';
lang.RECEIVED_GOOD_CONTAMINATION_PACKETS = 'Pacchetti';
lang.RECEIVED_GOOD_CONTAMINATION_BRIQUETTES = 'Bricchette';
lang.RECEIVED_GOOD_CONTAMINATION_INGOTS = 'Lingotti';
lang.RECEIVED_GOOD_CONTAMINATION_FINE_MATERIAL = 'Materiale fine';
lang.RECEIVED_GOOD_CONTAMINATION_RUNNINGS = 'Runnings';
lang.RECEIVED_GOOD_CONTAMINATION_TARE_INBOUND = 'Tare (inbound)';
lang.RECEIVED_GOOD_CONTAMINATION_TOTAL = 'Totali';
lang.RECEIVED_GOOD_CONTAMINATION_DRUMS = 'Drums';
lang.RECEIVED_GOOD_CONTAMINATION_BOXES = 'Scatole';
lang.RECEIVED_GOOD_CONTAMINATION_GRID_BOXES = 'Scatole di griglia';
lang.RECEIVED_GOOD_CONTAMINATION_BIGBAGS = 'Sacchi grandi';
lang.RECEIVED_GOOD_CONTAMINATION_PALLETS = 'Pallet';
lang.RECEIVED_GOOD_CONTAMINATION_TARE_OUTBOUND = 'Tare (in uscita)';
lang.RECEIVED_GOOD_CONTAMINATION_TURNINGS_COMPOSITION = 'composizione torniture';
lang.RECEIVED_GOOD_CONTAMINATION_MAGNETIC_CONTENT = 'contenuti magnetica';
lang.RECEIVED_GOOD_CONTAMINATION_METALLIC_TURNING = 'Turning metallico';
lang.RECEIVED_GOOD_CONTAMINATION_GRINDING_TURNING = 'Grinding Turning';
lang.RECEIVED_GOOD_CONTAMINATION_SIZES = 'Taglie';
lang.RECEIVED_GOOD_CONTAMINATION_SIZE_0_15 = 'Misura 0-15 cm';
lang.RECEIVED_GOOD_CONTAMINATION_SIZE_16_35 = 'Misura 16-35 cm';
lang.RECEIVED_GOOD_CONTAMINATION_SIZE_36_60 = 'Misura 36-60 cm';
lang.RECEIVED_GOOD_CONTAMINATION_SIZE_61_80 = 'Misura 61-80 cm';
lang.RECEIVED_GOOD_CONTAMINATION_SIZE_81_120 = 'Misura 81-120 cm';
lang.RECEIVED_GOOD_CONTAMINATION_SIZE_121_150 = 'Misura 121-150 cm';
lang.RECEIVED_GOOD_CONTAMINATION_SIZE_151_999 = 'Misura 150-999 cm';
lang.RECEIVED_GOOD_CONTAMINATION_SIZES_WARNING = 'La somma non è al 100%';
lang.RECEIVED_GOOD_CONTAMINATION_PACKAGING = 'Confezione';
lang.SORTINGREPORT_CLASSIFICATION_TITLE = 'Classificazione';
lang.SORTINGREPORT_CLASSIFICATION_VERY_LIGHT = 'molto leggero';
lang.SORTINGREPORT_CLASSIFICATION_LIGHT = 'luce';
lang.SORTINGREPORT_CLASSIFICATION_MIDDLE = 'mezzo';
lang.SORTINGREPORT_CLASSIFICATION_HEAVY = 'pesante';
lang.SORTINGREPORT_CLASSIFICATION_VERY_HEAVY = 'molto pesante';
lang.SORTINGREPORT_CLASSIFICATION_FE_SDS = 'Fe-SDS';
lang.SORTINGREPORT_CLASSIFICATION_CR_SDS = 'misto Cr-SDS';
lang.SORTINGREPORT_CLASSIFICATION_V13F_SDS = 'V13F-SDS';
lang.SORTINGREPORT_CLASSIFICATION_FQ_1_4510_SDS = '1.4510-SDS';
lang.SORTINGREPORT_CLASSIFICATION_FQ_1_4512_SDS = '1.4512-SDS';
lang.SORTINGREPORT_CLASSIFICATION_FQ_17ER_CR_SDS = '17er Cr-SDS';
lang.SORTINGREPORT_CLASSIFICATION_FQ_1_4016_SDS = '1.4016-SDS';
lang.SORTINGREPORT_CLASSIFICATION_FQ_1_4509_SDS = '1.4509-SDS';
lang.SORTINGREPORT_CLASSIFICATION_FQ_304_SDS = '304-SDS';
lang.SORTINGREPORT_CLASSIFICATION_CRNI_SDS = 'CrNi-SDS';
lang.SORTINGREPORT_CLASSIFICATION_CRNIMO_SDS = 'CrNiMo-SDS';
lang.SORTINGREPORT_CLASSIFICATION_FE_TUR = 'Fe-TUR';
lang.SORTINGREPORT_CLASSIFICATION_CR_CRNI_TUR = 'misto Cr / CrNi-TUR';
lang.SORTINGREPORT_CLASSIFICATION_CRNI_TUR = 'CrNi-TUR';
lang.SORTINGREPORT_CLASSIFICATION_CRNIMO_TUR = 'CrNiMo-TUR';
lang.SORTINGREPORT_CLASSIFICATION_OTHER = 'Altro';
lang.SORTINGREPORT_CLASSIFICATION_SHREDDER = 'distruttore di documenti';
lang.SORTINGREPORT_CLASSIFICATION_SOLIDS = 'solidi';
lang.SORTINGREPORT_CLASSIFICATION_TURNINGS = 'torniture';
lang.SORTINGREPORT_CLASSIFICATION_PACKAGE = 'Pacchetto';
lang.SORTINGREPORT_CLASSIFICATION_BRIKETTS = 'Briketts';
lang.SORTINGREPORT_CLASSIFICATION_INGOTS = 'lingotti';
lang.SORTINGREPORT_CLASSIFICATION_FINES = 'multe';
lang.SORTINGREPORT_CLASSIFICATION_WASTE_INCINERATION = 'L\'incenerimento dei rifiuti';
lang.SORTINGREPORT_CLASSIFICATION_RUNNINGS = 'Runnings';
lang.SORTINGREPORT_CLASSIFICATION_DEMOLITION_SCRAP = 'demolizione rottami';
lang.SORTINGREPORT_CLASSIFICATION_PRODUCTION_SCRAP = 'Produzione Scrap';
lang.SORTINGREPORT_CLASSIFICATION_COLLECTION_SCRAP = 'Raccolta Scrap';
lang.SORTINGREPORT_CLASSIFICATION_SHEARING_SCRAP = 'Shearing Scrap';
lang.SORTINGREPORT_CLASSIFICATION_TURNING_WITH_SOLIDS = 'Torniture con i solidi';
lang.SORTINGREPORT_PROCESSING_TIME_TITLE = 'Tempo di elaborazione';
lang.SORTINGREPORT_PROCESSING_TIME_CUTTING_HOURS = 'ORE TAGLIO piegamento/schiacciamento';
lang.SORTINGREPORT_PROCESSING_TIME_SPIDER_HOURS = 'ORE RAGNO';
lang.SORTINGREPORT_PROCESSING_TIME_CART_HOURS = 'ORE CARRELLO';
lang.SORTINGREPORT_PROCESSING_TIME_OPERATOR_HOURS = 'ORE OPERATORE';
lang.SORTINGREPORT_PROCESSING_TIME_UNIT_MINUTES = '.Minuti';
lang.SORTINGREPORT_PROCESSING_TIME_UNIT_TONS = '.Tonnellate';
lang.SORTINGREPORT_PROCESSING_TITLE = '.In lavorazione';
lang.SORTINGREPORT_PROCESSING_TIME_OPERATOR = '.Operatrice';
lang.SORTINGREPORT_PROCESSING_TIME_FORKLIFT = '.Carrello elevatore';
lang.SORTINGREPORT_PROCESSING_TIME_CRANE = '.Gru';
lang.SORTINGREPORT_PROCESSING_TIME_TORCH_CUTTING = '.Taglio della torcia';
lang.SORTINGREPORT_PROCESSING_TIME_PLASMA = '.Plasma';
lang.SORTINGREPORT_PROCESSING_TIME_CRANE_SHEAR = '.Cesoia per gru';
lang.SORTINGREPORT_PROCESSING_TIME_GUILLOTINE_SHEAR = '.Guillotine Shear';
lang.SORTINGREPORT_PROCESSING_TIME_DENSIFIER = '.Densificante';
lang.SORTINGREPORT_PROCESSING_TIME_BALER = '.Imballatrice';
lang.SORTINGREPORT_MEASUREMENT_TITLE = 'Misurazione';
lang.SORTINGREPORT_MEASUREMENT_SAMPLE_WET = 'Campione bagnato (g)';
lang.SORTINGREPORT_MEASUREMENT_SAMPLE_DRY = 'Campione asciutto (g)';
lang.SORTINGREPORT_MEASUREMENT_HUMIDITY = 'Umidità (%)';
lang.SORTINGREPORT_MEASUREMENT_FOR_MELTING = 'Per sciogliersi (g)';
lang.SORTINGREPORT_MEASUREMENT_INGOT = 'Lingotto (g)';
lang.SORTINGREPORT_MEASUREMENT_YIELD = 'Dare la precedenza (%)';
lang.SORTINGREPORT_MEASUREMENT_CONTAINER = 'Contenitore';
lang.SORTINGREPORT_LASERNET_COPIES = 'Copie';
lang.SORTINGREPORT_LASERNET_PRINTER_SUCCESS_MESSAGE = (numberOfCopies) => `${numberOfCopies} etichetta/e sono in coda e verranno stampate entro 1 minuto`;
lang.SORTINGREPORT_LASERNET_PRINTER_ERROR_MESSAGE = 'Errore di configurazione della stampante. Si prega di contattare il proprio amministratore';
lang.STATUS_LABINPUT_PRE_SAMPLE = 'Esempio pre';
lang.STATUS_LABINPUT_SAMPLE = 'Campione';
lang.STATUS_LABINPUT_RE_SAMPLE = 'Re campione';
lang.STATUS_LABINPUT_SORTATION = 'smistamento';
lang.STATUS_LABINPUT_NITON_ANALYSIS = 'Analisi Niton';
lang.STATUS_LABSTATUSES_IN_LAB = 'in laboratorio';
lang.STATUS_LABSTATUSES_DRYING = 'essiccazione';
lang.STATUS_LABSTATUSES_MELTING = 'Fusione';
lang.STATUS_LABSTATUSES_MELTED = 'Fuso';
lang.STATUS_LABSTATUSES_COMPLETE = 'Completare';
lang.STATUS_LABSTATUSES_REQUESTED = 'richiesto';
lang.STATUS_LABSTATUSES_SAMPLED = 'campionati';
lang.STATUS_LABSTATUSES_IN_PROGRESS = 'In corso';
lang.STATUS_DELIVERYSTATUSES_IN_WORK = 'In corso';
lang.STATUS_DELIVERYSTATUSES_READY_FOR_TIER_2 = 'Pronto per Tier 2';
lang.STATUS_DELIVERYSTATUSES_READY_FOR_SIGN_OFF = 'Pronto per TRI';
lang.STATUS_DELIVERYSTATUSES_PARTIALLY_SIGNED_OFF = 'Parzialmente firmato';
lang.STATUS_DELIVERYSTATUSES_SIGNED_OFF = 'firmato off';
lang.STATUS_DELIVERYSTATUSES_COMPLETE = 'Libero';
lang.STATUS_ADVISEDGOOD_WAITING = 'In attesa';
lang.STATUS_ADVISEDGOOD_CLAIMED = 'Ordinamento';
lang.STATUS_ADVISEDGOOD_UNLOADED = 'scarico';
lang.STATUS_ADVISEDGOOD_IN_LAB = 'in laboratorio';
lang.STATUS_ADVISEDGOOD_READY_FOR_TIER_2 = '.Pronto per la gestione del sito';
lang.STATUS_ADVISEDGOOD_READY_FOR_SIGN_OFF = 'Pronto per TRI';
lang.STATUS_ADVISEDGOOD_SIGNED_OFF = 'Firmato off';
lang.STATUS_ADVISEDGOOD_COMPLETE = 'Libero';
lang.DASHBOARD_TITLES_DASHBOARD = 'Cruscotto';
lang.DASHBOARD_TITLES_DELIVERIES_IN_PROGRESS = 'Consegne in corso';
lang.DASHBOARD_TITLES_DELIVERY_BY_STATUS = 'Consegna per Stato';
lang.DASHBOARD_TITLES_AVERAGE_SORTING_TIME = 'Tempo medio di smistamento';
lang.DASHBOARD_TITLES_TURN_AROUND_SORTING_TIME = 'Tempo medio di consegna';
lang.DASHBOARD_TITLES_SORTING_TIME_TREND = 'Tempo di smistamento ultime consegne';
lang.DASHBOARD_TITLES_FLAGGED_ITEMS = 'elementi contrassegnati';
lang.DASHBOARD_TITLES_TREES_SAVED = 'Gli alberi salvati';
lang.DASHBOARD_TITLES_SORTING_TIME_Y_LABEL = 'Tempo di ordinamento (ore)';
lang.DASHBOARD_TITLES_TURN_AROUND_TIME_Y_LABEL = 'Tempo di consegna (ore)';
lang.DASHBOARD_TITLES_SORTING_TIME_TOOLTIP = 'Tempo di ordinamento';
lang.DASHBOARD_TITLES_IN_PROGRESS_MONTHS = (condition) => `In corso ${condition} mesi`;
lang.DASHBOARD_TITLES_IN_PROGRESS_MONTH = (condition) => `In corso ${condition} mese`;
lang.DASHBOARD_TITLES_IN_PROGRESS_WEEK = (condition) => `In corso ${condition} settimana`;
lang.DASHBOARD_TITLES_ITEMS_FLAGGED_MONTHS = (condition) => `Articoli contrassegnati ${condition} mesi`;
lang.DASHBOARD_TITLES_ITEMS_FLAGGED_MONTH = (condition) => `Articoli contrassegnati ${condition} mese`;
lang.DASHBOARD_TITLES_ITEMS_FLAGGED_WEEK = (condition) => `Articles signalés ${condition} semaine`;
lang.USA_CONFIGURATION_CONFIGURATION = 'Configurazione';
lang.USA_CONFIGURATION_SHOW_CONTRACT_NUMBER = 'Visualizzare il numero di contratto';
lang.USA_CONFIGURATION_SHOW_ADVISED_GOOD_BREAKDOWN = 'Visualizza consigliato buona ripartizione';
lang.USA_CONFIGURATION_SHOW_ADVISED_GOOD_TARE = 'Visualizza consigliato buona tara';
lang.USA_CONFIGURATION_SHOW_ADVISED_GOOD_LOCATION = 'Mostra consigliato buona posizione';
lang.USA_CONFIGURATION_SHOW_DELIVERY_GROSS = 'Mostra dati lordo / tara / netto';
lang.USA_CONFIGURATION_SHOW_DELIVERY_TYPE = 'Visualizza tipo di consegna';
lang.USA_CONFIGURATION_SHOW_RADIATION_FILE = 'upload di file Radiazioni';
lang.USA_CONFIGURATION_SHOW_WEATHER_CONDITIONS = 'Visualizza le condizioni meteorologiche';
lang.USA_CONFIGURATION_STOCK_CODE = 'formato del codice della';
lang.USA_CONFIGURATION_WEIGHBRIDGE_TOLERANCE = 'tolleranza pesa a ponte';
lang.USA_CONFIGURATION_RADIATION_RILE_UPLOAD_MSG_ERROR = 'upload di file Radiazioni fallito, si prega di caricare di nuovo il file radiazioni';
lang.USA_CONFIGURATION_RADIATION_FILE_REQUIRED_MSG = 'è richiesto il file di radiazione';
lang.USA_CONFIGURATION_WEATHER_CONDITIONS = 'Condizioni meteo';
lang.USA_CONFIGURATION_DELIVERY_TYPE = 'Tipo di consegna';
lang.USA_CONFIGURATION_GROSS = 'Schifoso';
lang.USA_CONFIGURATION_TARE = 'Tara';
lang.USA_CONFIGURATION_NET = 'Netto';
lang.USA_CONFIGURATION_RADIATION_FILE = 'file di radiazioni';
lang.USA_CONFIGURATION_NO_RADIATION_FILE = 'Nessun file di radiazione';
lang.USA_CONFIGURATION_ADV_TARE = 'Adv. tara';
lang.PUSHNOTIFICATIONS_TITLES_NEW_DELIVERY_CREATED = '.Nuova consegna creata';
lang.PUSHNOTIFICATIONS_TITLES_DELIVERY_READY_FOR_SIGN_OFF = '.Consegna pronta per la firma';
lang.PUSHNOTIFICATIONS_TITLES_DELIVERY_SIGNED_OFF = '.Consegna firmata';
lang.PUSHNOTIFICATIONS_TITLES_DELIVERY_COMPLETED_BY_TRADER = '.Consegna completata dal commerciante';

export default lang;
